import React, { Component } from "react";
import { Link } from "react-router-dom";
import reportVisitService from "../reporting-visit/report-visit.service";

export default class PeriodicTable extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = "Periodic table | Chemistry";

    reportVisitService.report(window.location.pathname, document.title);
  }

  render() {
    return (
      <div className="main_div">
        <table className="periodic_table">
          <tr>
            <td className="empty" colSpan="1">
              {" "}
              &nbsp;
            </td>
            <td className="numbers">1</td>
            <td className="numbers">2</td>
            <td className="numbers">3</td>
            <td className="numbers">4</td>
            <td className="numbers">5</td>
            <td className="numbers">6</td>
            <td className="numbers">7</td>
            <td className="numbers">8</td>
            <td className="numbers">9</td>
            <td className="numbers">10</td>
            <td className="numbers">11</td>
            <td className="numbers">12</td>
            <td className="numbers">13</td>
            <td className="numbers">14</td>
            <td className="numbers">15</td>
            <td className="numbers">16</td>
            <td className="numbers">17</td>
            <td className="numbers">18</td>
          </tr>
          <tr>
            <td className="empty" colSpan="1">
              &nbsp;
            </td>
            <td className="numbers">I.A</td>
            <td className="empty" colSpan="16">
              &nbsp;
            </td>
            <td className="numbers">VIII.A</td>
          </tr>
          <tr>
            <td className="numbers">1</td>
            <td>
              <Link className="non_metals" to="/elements/hydrogen">
                H
              </Link>
            </td>
            <td className="numbers">II.A</td>
            <td className="empty" colSpan="10">
              &nbsp;
            </td>
            <td className="numbers">III.A</td>
            <td className="numbers">IV.A</td>
            <td className="numbers">V.A</td>
            <td className="numbers">VI.A</td>
            <td className="numbers">VII.A</td>
            <td>
              <Link className="noble_gases" to="/elements/helium">
                He
              </Link>
            </td>
          </tr>
          <tr>
            <td className="numbers">2</td>
            <td>
              <Link className="alkali_metals" to="/elements/lithium">
                Li
              </Link>
            </td>
            <td>
              <Link className="alkaline_earth_metals" to="/elements/beryllium">
                Be
              </Link>
            </td>
            <td className="empty" colSpan="10">
              &nbsp;
            </td>
            <td>
              <Link className="metalloid" to="/elements/boron">
                B
              </Link>
            </td>
            <td>
              <Link className="non_metals" to="/elements/carbon">
                C
              </Link>
            </td>
            <td>
              <Link className="non_metals" to="/elements/nitrogen">
                N
              </Link>
            </td>
            <td>
              <Link className="non_metals" to="/elements/oxygen">
                O
              </Link>
            </td>
            <td>
              <Link className="halogens" to="/elements/fluorine">
                F
              </Link>
            </td>
            <td>
              <Link className="noble_gases" to="/elements/neon">
                Ne
              </Link>
            </td>
          </tr>
          <tr>
            <td className="numbers">3</td>
            <td>
              <Link className="alkali_metals" to="/elements/sodium">
                Na
              </Link>
            </td>
            <td>
              <Link className="alkaline_earth_metals" to="/elements/magnesium">
                Mg
              </Link>
            </td>
            <td className="numbers">III.B</td>
            <td className="numbers">IV.B</td>
            <td className="numbers">V.B</td>
            <td className="numbers">VI.B</td>
            <td className="numbers">VII.B</td>
            <td className="numbers">-</td>
            <td className="numbers">VIII.B</td>
            <td className="numbers">-</td>
            <td className="numbers">I.B</td>
            <td className="numbers">II.B</td>
            <td>
              <Link className="other_metals" to="/elements/aluminium">
                Al
              </Link>
            </td>
            <td>
              <Link className="metalloid" to="/elements/silicon">
                Si
              </Link>
            </td>
            <td>
              <Link className="non_metals" to="/elements/phosphorus">
                P
              </Link>
            </td>
            <td>
              <Link className="non_metals" to="/elements/sulfur">
                S
              </Link>
            </td>
            <td>
              <Link className="halogens" to="/elements/chlorine">
                Cl
              </Link>
            </td>
            <td>
              <Link className="noble_gases" to="/elements/argon">
                Ar
              </Link>
            </td>
          </tr>
          <tr>
            <td className="numbers">4</td>
            <td>
              <Link className="alkali_metals" to="/elements/potassium">
                K
              </Link>
            </td>
            <td>
              <Link className="alkaline_earth_metals" to="/elements/calcium">
                Ca
              </Link>
            </td>
            <td>
              <Link className="transition_metals" to="/elements/scandium">
                Sc
              </Link>
            </td>
            <td>
              <Link className="transition_metals" to="/elements/titanium">
                Ti
              </Link>
            </td>
            <td>
              <Link className="transition_metals" to="/elements/vanadium">
                V
              </Link>
            </td>
            <td>
              <Link className="transition_metals" to="/elements/chromium">
                Cr
              </Link>
            </td>
            <td>
              <Link className="transition_metals" to="/elements/manganese">
                Mn
              </Link>
            </td>
            <td>
              <Link className="iron" to="/elements/iron">
                Fe
              </Link>
            </td>
            <td>
              <Link className="iron" to="/elements/cobalt">
                Co
              </Link>
            </td>
            <td>
              <Link className="iron" to="/elements/nickel">
                Ni
              </Link>
            </td>
            <td>
              <Link className="transition_metals" to="/elements/copper">
                Cu
              </Link>
            </td>
            <td>
              <Link className="transition_metals" to="/elements/zinc">
                Zn
              </Link>
            </td>
            <td>
              <Link className="other_metals" to="/elements/gallium">
                Ga
              </Link>
            </td>
            <td>
              <Link className="metalloid" to="/elements/germanium">
                Ge
              </Link>
            </td>
            <td>
              <Link className="metalloid" to="/elements/arsenic">
                As
              </Link>
            </td>
            <td>
              <Link className="non_metals" to="/elements/selenium">
                Se
              </Link>
            </td>
            <td>
              <Link className="halogens" to="/elements/bromine">
                Br
              </Link>
            </td>
            <td>
              <Link className="noble_gases" to="/elements/krypton">
                Kr
              </Link>
            </td>
          </tr>
          <tr>
            <td className="numbers">5</td>
            <td>
              <Link className="alkali_metals" to="/elements/rubidium">
                Rb
              </Link>
            </td>
            <td>
              <Link className="alkaline_earth_metals" to="/elements/strontium">
                Sr
              </Link>
            </td>
            <td>
              <Link className="transition_metals" to="/elements/yttrium">
                Y
              </Link>
            </td>
            <td>
              <Link className="transition_metals" to="/elements/niobium">
                Zr
              </Link>
            </td>
            <td>
              <Link className="transition_metals" to="/elements/niobium">
                Nb
              </Link>
            </td>
            <td>
              <Link className="transition_metals" to="/elements/molybdenum">
                Mo
              </Link>
            </td>
            <td>
              <Link className="transition_metals" to="/elements/technetium">
                Tc
              </Link>
            </td>
            <td>
              <Link className="ruthenium" to="/elements/ruthenium">
                Ru
              </Link>
            </td>
            <td>
              <Link className="ruthenium" to="/elements/rhodium">
                Rh
              </Link>
            </td>
            <td>
              <Link className="ruthenium" to="/elements/palladium">
                Pd
              </Link>
            </td>
            <td>
              <Link className="transition_metals" to="/elements/silver">
                Ag
              </Link>
            </td>
            <td>
              <Link className="transition_metals" to="/elements/cadmium">
                Cd
              </Link>
            </td>
            <td>
              <Link className="other_metals" to="/elements/indium">
                In
              </Link>
            </td>
            <td>
              <Link className="other_metals" to="/elements/tin">
                Sn
              </Link>
            </td>
            <td>
              <Link className="metalloid" to="/elements/antimony">
                Sb
              </Link>
            </td>
            <td>
              <Link className="metalloid" to="/elements/tellurium">
                Te
              </Link>
            </td>
            <td>
              <Link className="halogens" to="/elements/iodine">
                I
              </Link>
            </td>
            <td>
              <Link className="noble_gases" to="/elements/xenon">
                Xe
              </Link>
            </td>
          </tr>
          <tr>
            <td className="numbers">6</td>
            <td>
              <Link className="alkali_metals" to="/elements/caesium">
                Cs
              </Link>
            </td>
            <td>
              <Link className="alkaline_earth_metals" to="/elements/barium">
                Ba
              </Link>
            </td>
            <td className="empty" colSpan="1">
              &nbsp;
            </td>
            <td>
              <Link className="transition_metals" to="/elements/hafnium">
                Hf
              </Link>
            </td>
            <td>
              <Link className="transition_metals" to="/elements/tantalum">
                Ta
              </Link>
            </td>
            <td>
              <Link className="transition_metals" to="/elements/tungsten">
                W
              </Link>
            </td>
            <td>
              <Link className="transition_metals" to="/elements/rhenium">
                Re
              </Link>
            </td>
            <td>
              <Link className="ruthenium" to="/elements/osmium">
                Os
              </Link>
            </td>
            <td>
              <Link className="ruthenium" to="/elements/iridium">
                Ir
              </Link>
            </td>
            <td>
              <Link className="ruthenium" to="/elements/platinum">
                Pt
              </Link>
            </td>
            <td>
              <Link className="transition_metals" to="/elements/gold">
                Au
              </Link>
            </td>
            <td>
              <Link className="transition_metals" to="/elements/mercury">
                Hg
              </Link>
            </td>
            <td>
              <Link className="other_metals" to="/elements/thallium">
                Tl
              </Link>
            </td>
            <td>
              <Link className="other_metals" to="/elements/lead">
                Pb
              </Link>
            </td>
            <td>
              <Link className="other_metals" to="/elements/bismuth">
                Bi
              </Link>
            </td>
            <td>
              <Link className="metalloid" to="/elements/polonium">
                Po
              </Link>
            </td>
            <td>
              <Link className="halogens" to="/elements/astatine">
                At
              </Link>
            </td>
            <td>
              <Link className="noble_gases" to="/elements/radon">
                Rn
              </Link>
            </td>
          </tr>
          <tr>
            <td className="numbers">7</td>
            <td>
              <Link className="alkali_metals" to="/elements/francium">
                Fr
              </Link>
            </td>
            <td>
              <Link className="alkaline_earth_metals" to="/elements/radium">
                Ra
              </Link>
            </td>
            <td className="empty" colSpan="1">
              &nbsp;
            </td>
            <td>
              <Link className="transition_metals" to="/elements/ruthenium">
                Rf
              </Link>
            </td>
            <td>
              <Link className="transition_metals" to="/elements/dubnium">
                Db
              </Link>
            </td>
            <td>
              <Link className="transition_metals" to="/elements/seaborgium">
                Sg
              </Link>
            </td>
            <td>
              <Link className="transition_metals" to="/elements/bohrium">
                Bh
              </Link>
            </td>
            <td>
              <Link className="transition_metals" to="/elements/hassium">
                Hs
              </Link>
            </td>
            <td>
              <Link className="transition_metals" to="/elements/meitnerium">
                Mt
              </Link>
            </td>
            <td>
              <Link className="transition_metals" to="/elements/darmstadtium">
                Ds
              </Link>
            </td>
            <td>
              <Link className="transition_metals" to="/elements/roentgenium">
                Rg
              </Link>
            </td>
            <td>
              <Link className="transition_metals" to="/elements/copernicium">
                Cn
              </Link>
            </td>
            <td>
              <Link className="other_metals" to="/elements/nihonium">
                Nh
              </Link>
            </td>
            <td>
              <Link className="other_metals" to="/elements/flerovium">
                Fl
              </Link>
            </td>
            <td>
              <Link className="other_metals" to="/elements/moscovium">
                Mc
              </Link>
            </td>
            <td>
              <Link className="other_metals" to="/elements/livermorium">
                Lv
              </Link>
            </td>
            <td>
              <Link className="halogens" to="/elements/tennessine">
                Ts
              </Link>
            </td>
            <td>
              <Link className="noble_gases" to="/elements/oganesson">
                Og
              </Link>
            </td>
          </tr>
          <tr>
            <td className="empty" colSpan="18">
              &nbsp;
            </td>
          </tr>
          <tr>
            <td className="empty" colSpan="3">
              &nbsp;
            </td>
            <td>
              <Link className="lanthanides" to="/elements/lanthanum">
                La
              </Link>
            </td>
            <td>
              <Link className="lanthanides" to="/elements/cerium">
                Ce
              </Link>
            </td>
            <td>
              <Link className="lanthanides" to="/elements/praseodymium">
                Pr
              </Link>
            </td>
            <td>
              <Link className="lanthanides" to="/elements/neodymium">
                Nd
              </Link>
            </td>
            <td>
              <Link className="lanthanides" to="/elements/promethium">
                Pm
              </Link>
            </td>
            <td>
              <Link className="lanthanides" to="/elements/samarium">
                Sm
              </Link>
            </td>
            <td>
              <Link className="lanthanides" to="/elements/europium">
                Eu
              </Link>
            </td>
            <td>
              <Link className="lanthanides" to="/elements/gadolinium">
                Gd
              </Link>
            </td>
            <td>
              <Link className="lanthanides" to="/elements/terbium">
                Tb
              </Link>
            </td>
            <td>
              <Link className="lanthanides" to="/elements/dysprosium">
                Dy
              </Link>
            </td>
            <td>
              <Link className="lanthanides" to="/elements/holmium">
                Ho
              </Link>
            </td>
            <td>
              <Link className="lanthanides" to="/elements/erbium">
                Er
              </Link>
            </td>
            <td>
              <Link className="lanthanides" to="/elements/thulium">
                Tm
              </Link>
            </td>
            <td>
              <Link className="lanthanides" to="/elements/ytterbium">
                Yb
              </Link>
            </td>
            <td>
              <Link className="lanthanides" to="/elements/lutetium">
                Lu
              </Link>
            </td>
          </tr>
          <tr>
            <td className="empty" colSpan="3">
              &nbsp;
            </td>
            <td>
              <Link className="actinoids" to="/elements/actinium">
                Ac
              </Link>
            </td>
            <td>
              <Link className="actinoids" to="/elements/thorium">
                Th
              </Link>
            </td>
            <td>
              <Link className="actinoids" to="/elements/protactinium">
                Pa
              </Link>
            </td>
            <td>
              <Link className="actinoids" to="/elements/uranium">
                U
              </Link>
            </td>
            <td>
              <Link className="actinoids" to="/elements/neptunium">
                Np
              </Link>
            </td>
            <td>
              <Link className="actinoids" to="/elements/plutonium">
                Pu
              </Link>
            </td>
            <td>
              <Link className="actinoids" to="/elements/americium">
                Am
              </Link>
            </td>
            <td>
              <Link className="actinoids" to="/elements/curium">
                Cm
              </Link>
            </td>
            <td>
              <Link className="actinoids" to="/elements/beryllium">
                Bk
              </Link>
            </td>
            <td>
              <Link className="actinoids" to="/elements/californium">
                Cf
              </Link>
            </td>
            <td>
              <Link className="actinoids" to="/elements/einsteinium">
                Es
              </Link>
            </td>
            <td>
              <Link className="actinoids" to="/elements/fermium">
                Fm
              </Link>
            </td>
            <td>
              <Link className="actinoids" to="/elements/mendelevium">
                Md
              </Link>
            </td>
            <td>
              <Link className="actinoids" to="/elements/nobelium">
                No
              </Link>
            </td>
            <td>
              <Link className="actinoids" to="/elements/lawrencium">
                Lr
              </Link>
            </td>
          </tr>
        </table>
      </div>
    );
  }
}
