import http from "./http-common-reporting";
import { v4 as uuidv4 } from "uuid";
import {
  browserName,
  browserVersion,
  osName,
  osVersion,
} from "react-device-detect";

let visitorId;

class ReportVisitService {
  report(path, title) {
    if (!visitorId) {
      visitorId = uuidv4();
    }

    const data = {
      id: "fb5b7de2-1d97-4467-84c9-11578fa5de03",
      path: path,
      pageName: title,
      visitorId: visitorId,
      browserName: browserName,
      browserVersion: browserVersion,
      osName: osName,
      osVersion: osVersion,
    };
    http.post("/", data);
  }
}

export default new ReportVisitService();
