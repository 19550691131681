import React, { Component } from "react";

import twitterIcon from "../icons/twitter-gray.svg";
import emailIcon from "../icons/email-gray.svg";
import linkedInIcon from "../icons/linkedin-gray.svg";

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultFooterClass: "footer footer_bottom",
    };
  }

  render() {
    const footerClass = this.props.customClass
      ? this.props.customClass
      : this.state.defaultFooterClass;
    return (
      <footer className={footerClass}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.davidsimak.dev"
          className="footer_text"
        >
          <b>David</b>Šimák | Self-taught JAVA/Kotlin developer
        </a>
        <div className="footer_links">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="mailto://david@davidsimak.dev"
          >
            <img src={emailIcon} />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/david-simak/"
          >
            <img src={linkedInIcon} />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/davidsimakdev"
          >
            <img src={twitterIcon} />
          </a>
        </div>
      </footer>
    );
  }
}
