import React, { Component } from "react";
import elementsHelper from "../helpers/elements.helper";
import reportVisitService from "../reporting-visit/report-visit.service";

export default class Element extends Component {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);

    this.state = {
      data: null,
      oldPath: "",
    };
  }

  componentDidMount() {
    document.title = "Chemistry";
    this.getData();
  }

  componentDidUpdate() {
    this.getData();
  }

  getData() {
    const path = window.location.pathname;
    if (this.state.oldPath !== path) {
      var sPath = path.replace("/elements/", "");
      if (sPath.endsWith("/")) sPath = sPath.substring(0, sPath.length - 1);

      var elementData;
      switch (sPath) {
        case "hydrogen":
          elementData = elementsHelper.getHydrogen();
          break;
        case "helium":
          elementData = elementsHelper.getHelium();
          break;
        case "lithium":
          elementData = elementsHelper.getLithium();
          break;
        case "beryllium":
          elementData = elementsHelper.getBeryllium();
          break;
        case "boron":
          elementData = elementsHelper.getBoron();
          break;
        case "carbon":
          elementData = elementsHelper.getCarbon();
          break;
        case "nitrogen":
          elementData = elementsHelper.getNitrogen();
          break;
        case "oxygen":
          elementData = elementsHelper.getOxygen();
          break;
        case "fluorine":
          elementData = elementsHelper.getFluorine();
          break;
        case "neon":
          elementData = elementsHelper.getNeon();
          break;
        case "sodium":
          elementData = elementsHelper.getSodium();
          break;
        case "magnesium":
          elementData = elementsHelper.getMagnesium();
          break;
        case "aluminium":
          elementData = elementsHelper.getAluminium();
          break;
        case "silicon":
          elementData = elementsHelper.getSilicon();
          break;
        case "phosphorus":
          elementData = elementsHelper.getPhosphorus();
          break;
        case "sulfur":
          elementData = elementsHelper.getSulfur();
          break;
        case "chlorine":
          elementData = elementsHelper.getChlorine();
          break;
        case "argon":
          elementData = elementsHelper.getArgon();
          break;
        case "potassium":
          elementData = elementsHelper.getPotassium();
          break;
        case "calcium":
          elementData = elementsHelper.getCalcium();
          break;
        case "scandium":
          elementData = elementsHelper.getScandium();
          break;
        case "titanium":
          elementData = elementsHelper.getTitanium();
          break;
        case "vanadium":
          elementData = elementsHelper.getVanadium();
          break;
        case "chromium":
          elementData = elementsHelper.getChromium();
          break;
        case "manganese":
          elementData = elementsHelper.getManganese();
          break;
        case "iron":
          elementData = elementsHelper.getIron();
          break;
        case "cobalt":
          elementData = elementsHelper.getCobalt();
          break;
        case "nickel":
          elementData = elementsHelper.getNickel();
          break;
        case "copper":
          elementData = elementsHelper.getCopper();
          break;
        case "zinc":
          elementData = elementsHelper.getZinc();
          break;
        case "gallium":
          elementData = elementsHelper.getGallium();
          break;
        case "germanium":
          elementData = elementsHelper.getGermanium();
          break;
        case "arsenic":
          elementData = elementsHelper.getArsenic();
          break;
        case "selenium":
          elementData = elementsHelper.getSelenium();
          break;
        case "bromine":
          elementData = elementsHelper.getBromine();
          break;
        case "krypton":
          elementData = elementsHelper.getKrypton();
          break;
        case "rubidium":
          elementData = elementsHelper.getRubidium();
          break;
        case "strontium":
          elementData = elementsHelper.getStrontium();
          break;
        case "yttrium":
          elementData = elementsHelper.getYttrium();
          break;
        case "zirconium":
          elementData = elementsHelper.getZirconium();
          break;
        case "niobium":
          elementData = elementsHelper.getNiobium();
          break;
        case "molybdenum":
          elementData = elementsHelper.getMolybdenum();
          break;
        case "technetium":
          elementData = elementsHelper.getTechnetium();
          break;
        case "ruthenium":
          elementData = elementsHelper.getRuthenium();
          break;
        case "rhodium":
          elementData = elementsHelper.getRhodium();
          break;
        case "palladium":
          elementData = elementsHelper.getPalladium();
          break;
        case "silver":
          elementData = elementsHelper.getSilver();
          break;
        case "cadmium":
          elementData = elementsHelper.getCadmium();
          break;
        case "indium":
          elementData = elementsHelper.getIndium();
          break;
        case "tin":
          elementData = elementsHelper.getTin();
          break;
        case "antimony":
          elementData = elementsHelper.getAntimony();
          break;
        case "tellurium":
          elementData = elementsHelper.getTellurium();
          break;
        case "iodine":
          elementData = elementsHelper.getIodine();
          break;
        case "xenon":
          elementData = elementsHelper.getXenon();
          break;
        case "caesium":
          elementData = elementsHelper.getCaesium();
          break;
        case "barium":
          elementData = elementsHelper.getBarium();
          break;
        case "lanthanum":
          elementData = elementsHelper.getLanthanum();
          break;
        case "cerium":
          elementData = elementsHelper.getCerium();
          break;
        case "praseodymium":
          elementData = elementsHelper.getPraseodymium();
          break;
        case "neodymium":
          elementData = elementsHelper.getNeodymium();
          break;
        case "promethium":
          elementData = elementsHelper.getPromethium();
          break;
        case "samarium":
          elementData = elementsHelper.getSamarium();
          break;
        case "europium":
          elementData = elementsHelper.getEuropium();
          break;
        case "gadolinium":
          elementData = elementsHelper.getGadolinium();
          break;
        case "terbium":
          elementData = elementsHelper.getTerbium();
          break;
        case "dysprosium":
          elementData = elementsHelper.getDysprosium();
          break;
        case "holmium":
          elementData = elementsHelper.getHolmium();
          break;
        case "erbium":
          elementData = elementsHelper.getErbium();
          break;
        case "thulium":
          elementData = elementsHelper.getThulium();
          break;
        case "ytterbium":
          elementData = elementsHelper.getYtterbium();
          break;
        case "lutetium":
          elementData = elementsHelper.getLutetium();
          break;
        case "hafnium":
          elementData = elementsHelper.getHafnium();
          break;
        case "tantalum":
          elementData = elementsHelper.getTantalum();
          break;
        case "tungsten":
          elementData = elementsHelper.getTungsten();
          break;
        case "rhenium":
          elementData = elementsHelper.getRhenium();
          break;
        case "osmium":
          elementData = elementsHelper.getOsmium();
          break;
        case "iridium":
          elementData = elementsHelper.getIridium();
          break;
        case "platinum":
          elementData = elementsHelper.getPlatinum();
          break;
        case "gold":
          elementData = elementsHelper.getGold();
          break;
        case "mercury":
          elementData = elementsHelper.getMercury();
          break;
        case "thallium":
          elementData = elementsHelper.getThallium();
          break;
        case "lead":
          elementData = elementsHelper.getLead();
          break;
        case "bismuth":
          elementData = elementsHelper.getBismuth();
          break;
        case "polonium":
          elementData = elementsHelper.getPolonium();
          break;
        case "astatine":
          elementData = elementsHelper.getAstatine();
          break;
        case "radon":
          elementData = elementsHelper.getRadon();
          break;
        case "francium":
          elementData = elementsHelper.getFrancium();
          break;
        case "radon":
          elementData = elementsHelper.getRadon();
          break;
        case "actinium":
          elementData = elementsHelper.getActinium();
          break;
        case "thorium":
          elementData = elementsHelper.getThorium();
          break;
        case "protactinium":
          elementData = elementsHelper.getProtactinium();
          break;
        case "uranium":
          elementData = elementsHelper.getUranium();
          break;
        case "neptunium":
          elementData = elementsHelper.getNeptunium();
          break;
        case "plutonium":
          elementData = elementsHelper.getPlutonium();
          break;
        case "americium":
          elementData = elementsHelper.getAmericium();
          break;
        case "curium":
          elementData = elementsHelper.getCurium();
          break;
        case "beryllium":
          elementData = elementsHelper.getBeryllium();
          break;
        case "californium":
          elementData = elementsHelper.getCalifornium();
          break;
        case "einsteinium":
          elementData = elementsHelper.getEinsteinium();
          break;
        case "fermium":
          elementData = elementsHelper.getFermium();
          break;
        case "mendelevium":
          elementData = elementsHelper.getMendelevium();
          break;
        case "nobelium":
          elementData = elementsHelper.getNobelium();
          break;
        case "lawrencium":
          elementData = elementsHelper.getLawrencium();
          break;
        case "ruthenium":
          elementData = elementsHelper.getRuthenium();
          break;
        case "dubnium":
          elementData = elementsHelper.getDubnium();
          break;
        case "seaborgium":
          elementData = elementsHelper.getSeaborgium();
          break;
        case "bohrium":
          elementData = elementsHelper.getBohrium();
          break;
        case "hassium":
          elementData = elementsHelper.getHassium();
          break;
        case "meitnerium":
          elementData = elementsHelper.getMeitnerium();
          break;
        case "darmstadtium":
          elementData = elementsHelper.getDarmstadtium();
          break;
        case "roentgenium":
          elementData = elementsHelper.getRoentgenium();
          break;
        case "copernicium":
          elementData = elementsHelper.getCopernicium();
          break;
        case "nihonium":
          elementData = elementsHelper.getNihonium();
          break;
        case "flerovium":
          elementData = elementsHelper.getFlerovium();
          break;
        case "moscovium":
          elementData = elementsHelper.getMoscovium();
          break;
        case "livermorium":
          elementData = elementsHelper.getLivermorium();
          break;
        case "tennessine":
          elementData = elementsHelper.getTennessine();
          break;
        case "oganesson":
          elementData = elementsHelper.getOganesson();
          break;
      }
      this.setState(
        {
          data: elementData,
          oldPath: path,
        },
        () => {
          document.title = this.state.data.title + " | Chemistry";
          reportVisitService.report(window.location.pathname, document.title);
        }
      );
    }
  }

  // <sup>&ensp;</sup>
  render() {
    return (
      <div className="element_div">
        {this.state.data !== null ? (
          <div>
            <h1 className="element_title">{this.state.data.title}</h1>

            <label className="title_label">General properties</label>

            <table className="general_properties">
              <tr>
                <td>
                  <label className="bold_label">Symbol:</label>
                </td>
                <td>
                  <label className="value_label">
                    {this.state.data.generalProperties.symbol}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label className="bold_label">Element category:</label>
                </td>
                <td>
                  <label className="value_label">
                    {this.state.data.generalProperties.elementCategory}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label className="bold_label">Period:</label>
                </td>
                <td>
                  <label className="value_label">
                    {this.state.data.generalProperties.period}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label className="bold_label">Proton number:</label>
                </td>
                <td>
                  <label className="value_label">
                    {this.state.data.generalProperties.protonNumber}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label className="bold_label">Atomic weight:</label>
                </td>
                <td>
                  <label className="value_label">
                    {this.state.data.generalProperties.atomicWeight}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label className="bold_label">Group:</label>
                </td>
                <td>
                  <label className="value_label">
                    {this.state.data.generalProperties.group}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label className="bold_label">Appearance:</label>
                </td>
                <td>
                  <label className="value_label">
                    {this.state.data.generalProperties.appearance}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label className="bold_label">Electron configuration:</label>
                </td>
                <td>
                  <label className="value_label">
                    {this.state.data.generalProperties.electronConfiguration}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label className="bold_label">Radioactive:</label>
                </td>
                <td>
                  <label className="value_label">
                    {this.state.data.generalProperties.radioactive
                      ? "Yes"
                      : "No"}
                  </label>
                </td>
              </tr>
            </table>

            <label className="title_label">Physical properties</label>

            <table className="physical_properties">
              <tr>
                <td>
                  <label className="bold_label">Phase:</label>
                </td>
                <td>
                  <label className="value_label">
                    {this.state.data.physicalProperties.phase}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label className="bold_label">Melting point:</label>
                </td>
                <td>
                  <label className="value_label">
                    {this.state.data.physicalProperties.meltingPoint}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label className="bold_label">Boiling point:</label>
                </td>
                <td>
                  <label className="value_label">
                    {this.state.data.physicalProperties.boilingPoint}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label className="bold_label">Density:</label>
                </td>
                <td>
                  <label
                    className="value_label"
                    dangerouslySetInnerHTML={{
                      __html: this.state.data.physicalProperties.density,
                    }}
                  />
                </td>
              </tr>
            </table>

            <label className="title_label">Atomic properties</label>

            <table className="atomic_properties">
              <tr>
                <td>
                  <label className="bold_label">Oxidation states:</label>
                </td>
                <td>
                  <label className="value_label">
                    {this.state.data.atomicProperties.oxidationStates}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label className="bold_label">Electronegativity:</label>
                </td>
                <td>
                  <label className="value_label">
                    {this.state.data.atomicProperties.electronegativity}
                  </label>
                </td>
              </tr>
            </table>

            <label className="title_label">Miscellanea</label>

            <table className="miscellanea">
              <tr>
                <td>
                  <label className="bold_label">Crystal structure:</label>
                </td>
                <td>
                  <label className="value_label">
                    {this.state.data.miscellanea.crystalStructure}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label className="bold_label">Electrical resistivity:</label>
                </td>
                <td>
                  <label
                    className="value_label"
                    dangerouslySetInnerHTML={{
                      __html: this.state.data.miscellanea.electricalResistivity,
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label className="bold_label">CAS Number:</label>
                </td>
                <td>
                  <label className="value_label">
                    {this.state.data.miscellanea.casNumber}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label className="bold_label">Year of discovery:</label>
                </td>
                <td>
                  <label className="value_label">
                    {this.state.data.miscellanea.yearOfDiscovery}
                  </label>
                </td>
              </tr>
            </table>
          </div>
        ) : (
          <div>
            <label className="nodata">No data</label>
          </div>
        )}
      </div>
    );
  }
}
