class ElementsHelper {
    getPlatinum() {
        return {
            title: "Platinum",
            generalProperties: {
                symbol: "Pt",
                elementCategory: "Transition metals",
                period: "6",
                protonNumber: "78",
                atomicWeight: "195,084",
                group: "VIII.B",
                appearance: "silvery-white",
                electronConfiguration: "[Xe]4f14 5d9 6s1",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "2041.4 K (1768.3 °C, 3214.9 °F)",
                boilingPoint: "4098 K (3825 °C, 6917 °F)",
                density: "21,45 g/cm³"
            },
            atomicProperties: {
                oxidationStates: "- II, -I, I, II, III, IV, V, VI",
                electronegativity: "2,28"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                electricalResistivity: "105 nΩ·m",
                casNumber: "7440-06-4",
                yearOfDiscovery: "1735"
            }
        }
    }


    getLutetium() {
        return {
            title: "Lutetium",
            generalProperties: {
                symbol: "Lu",
                elementCategory: "Lanthanoid",
                period: "6",
                protonNumber: "71",
                atomicWeight: "174,947",
                group: "III.B",
                appearance: "silvery-white",
                electronConfiguration: "[Xe]4f14 5d1 6s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "1925 K (1652 °C, 3006 °F)",
                boilingPoint: "3675 K (3402 °C, 6156 °F)",
                density: "9,841 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "I, II, III",
                electronegativity: "1,27"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "582 nΩ·m",
                casNumber: "7439-94-3",
                yearOfDiscovery: "1907"
            }
        }
    }

    getNeodymium() {
        return {
            title: "Neodymium",
            generalProperties: {
                symbol: "Nd",
                elementCategory: "Lanthanoid",
                period: "6",
                protonNumber: "60",
                atomicWeight: "144.242",
                group: "III.B",
                appearance: "Silvery-white",
                electronConfiguration: "[Xe] 4f4 6s2",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "1297 K (1024 °C, 1875 °F)",
                boilingPoint: "3347 K (3074 °C, 5565 °F)",
                density: "7.01 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "II, III, IV",
                electronegativity: "1.14"
            },
            miscellanea: {
                crystalStructure: "double hexagonal close-package",
                electricalResistivity: "643 nΩ·m",
                casNumber: "7440-00-8",
                yearOfDiscovery: "1885"
            }
        }
    }

    getKrypton() {
        return {
            title: "Krypton",
            generalProperties: {
                symbol: "Kr",
                elementCategory: "Rare gases",
                period: "4",
                protonNumber: "36",
                atomicWeight: "83.798",
                group: "VIII.A",
                appearance: "Colorless gas",
                electronConfiguration: "[Ar] 3d10 4s2 4p6",
                radioactive: false
            },
            physicalProperties: {
                phase: "Gas",
                meltingPoint: "115.78 K (−157.37 °C, −251.27 °F)",
                boilingPoint: "119.93 K (−153.415 °C, −244.147 °F)",
                density: "0.003749 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "II",
                electronegativity: "3.0"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                casNumber: "7439-90-9",
                yearOfDiscovery: "1898"
            }
        }
    }

    getLithium() {
        return {
            title: "Lithium",
            generalProperties: {
                symbol: "Li",
                elementCategory: "Alkali metals",
                period: "2",
                protonNumber: "3",
                atomicWeight: "6.9431",
                group: "I.A",
                appearance: "Silvery white",
                electronConfiguration: "[He]2s1",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "453.65 K (180.50 °C, 356.90 °F)",
                boilingPoint: "1603 K (1330 °C, 2426 °F)",
                density: "534 kg/m<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "I",
                electronegativity: "0.98"
            },
            miscellanea: {
                crystalStructure: "body-centered cubic",
                electricalResistivity: "92.8 nΩ/m",
                casNumber: "7439-93-2",
                yearOfDiscovery: "1817"
            }
        }
    }

    getDysprosium() {
        return {
            title: "Dysprosium",
            generalProperties: {
                symbol: "Dy",
                elementCategory: "Lanthanoid",
                period: "6",
                protonNumber: "66",
                atomicWeight: "162,500",
                group: "III.B",
                appearance: "silvery-white",
                electronConfiguration: "[Xe]4f10 6s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "1680 K (1407 °C, 2565 °F)",
                boilingPoint: "2840 K (2562 °C, 4653 °F)",
                density: "8.540 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "I, II, III, IV",
                electronegativity: "1,22"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "926 nΩ·m",
                casNumber: "7429-91-6",
                yearOfDiscovery: "1886"
            }
        }
    }


    getRhenium() {
        return {
            title: "Rhenium",
            generalProperties: {
                symbol: "Re",
                elementCategory: "Transition metals",
                period: "6",
                protonNumber: "75",
                atomicWeight: "186,207",
                group: "VII.B",
                appearance: "silvery-grayish",
                electronConfiguration: "[Xe]4f14 5d5 6s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "3459 K (3186 °C, 5767 °F)",
                boilingPoint: "5903 K (5630 °C, 10,170 °F)",
                density: "21,02 g/cm³"
            },
            atomicProperties: {
                oxidationStates: "III, IV, V, VI, VII",
                electronegativity: "1,9"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "193 nΩ·m",
                casNumber: "7440-15-5",
                yearOfDiscovery: "1925"
            }
        }
    }

    getBerkelium() {
        return {
            title: "Berkelium",
            generalProperties: {
                symbol: "Bk",
                elementCategory: "Actinium",
                period: "7",
                protonNumber: "97",
                atomicWeight: "247,0702",
                group: "III.B",
                appearance: "silvery",
                electronConfiguration: "[Rn]5f9 7s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "1259 K (986 °C, 1807 °F)",
                boilingPoint: "2900 K (2627 °C, 4760 °F)",
                density: "14,78 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "II, III, IV",
                electronegativity: "1,3"
            },
            miscellanea: {
                crystalStructure: "double hexagonal close-packed",
                electricalResistivity: "-",
                casNumber: "7440-40-6",
                yearOfDiscovery: "1949"
            }
        }
    }

    getHolmium() {
        return {
            title: "Holmium",
            generalProperties: {
                symbol: "Ho",
                elementCategory: "Lanthanoid",
                period: "6",
                protonNumber: "67",
                atomicWeight: "164,93032",
                group: "III.B",
                appearance: "silvery-white",
                electronConfiguration: "[Xe]4f11 6s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "1734 K (1461 °C, 2662 °F)",
                boilingPoint: "2873 K (2600 °C, 4712 °F)",
                density: "8,79 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "I, II, III",
                electronegativity: "1,23"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "814 nΩ·m",
                casNumber: "7440-60-0",
                yearOfDiscovery: "1878"
            }
        }
    }

    getFlerovium() {
        return {
            title: "Flerovium",
            generalProperties: {
                symbol: "Fl",
                elementCategory: "Other metals",
                period: "7",
                protonNumber: "114",
                atomicWeight: "289,19",
                group: "IV.A",
                appearance: "-",
                electronConfiguration: "[Rn] 5f14 6d10 7s2 7p2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "-",
                boilingPoint: "-",
                density: "14 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "0, I, II, IV, VI",
                electronegativity: "-"
            },
            miscellanea: {
                crystalStructure: "-",
                electricalResistivity: "-",
                casNumber: "54085-16-4",
                yearOfDiscovery: "1999"
            }
        }
    }

    getErbium() {
        return {
            title: "Erbium",
            generalProperties: {
                symbol: "Er",
                elementCategory: "Lanthanoid",
                period: "6",
                protonNumber: "68",
                atomicWeight: "167,259",
                group: "III.B",
                appearance: "silvery-white",
                electronConfiguration: "[Xe]4f12 6s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "1802 K (1529 °C, 2784 °F)",
                boilingPoint: "3141 K (2868 °C, 5194 °F)",
                density: "9.066 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "I, II, III",
                electronegativity: "1,24"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "0.860 µΩ·m",
                casNumber: "7440-52-0",
                yearOfDiscovery: "1843"
            }
        }
    }

    getOganesson() {
        return {
            title: "Oganesson",
            generalProperties: {
                symbol: "Og",
                elementCategory: "Rare gases",
                period: "7",
                protonNumber: "118",
                atomicWeight: "293,21495",
                group: "VII.A",
                appearance: "-",
                electronConfiguration: "[Rn] 5f14 6d10 7s2 7p6",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "-",
                boilingPoint: "350±30 K (80±30 °C, 170±50 °F)",
                density: "4.9–5.1 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "−I, 0, I, II, IV, VI",
                electronegativity: "-"
            },
            miscellanea: {
                crystalStructure: "-",
                electricalResistivity: "-",
                casNumber: "54144-19-3",
                yearOfDiscovery: "1999"
            }
        }
    }

    getSilver() {
        return {
            title: "Silver",
            generalProperties: {
                symbol: "Ag",
                elementCategory: "Transition metals",
                period: "5",
                protonNumber: "47",
                atomicWeight: "107.8682",
                group: "I.B",
                appearance: "Lustrous white metal",
                electronConfiguration: "[Kr] 4d10 5s1",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "1234.93 K (961.78 °C, 1763.2 °F)",
                boilingPoint: "2435 K (2162 °C, 3924 °F)",
                density: "10.49 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "I. II",
                electronegativity: "1.93"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                electricalResistivity: "15.87 nΩ·m",
                casNumber: "7440-22-4"
            }
        }
    }

    getDarmstadtium() {
        return {
            title: "Darmstadtium",
            generalProperties: {
                symbol: "Ds",
                elementCategory: "Transition metals",
                period: "7",
                protonNumber: "110",
                atomicWeight: "281,16",
                group: "VIII.B",
                appearance: "-",
                electronConfiguration: "[Rn]5f14 6d9 7s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "-",
                boilingPoint: "-",
                density: "34.8 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "0, II, IV, VI, VIII",
                electronegativity: "-"
            },
            miscellanea: {
                crystalStructure: "body-centered cubic",
                electricalResistivity: "-",
                casNumber: "54083-77-1",
                yearOfDiscovery: "1994"
            }
        }
    }

    getTungsten() {
        return {
            title: "Tungsten",
            generalProperties: {
                symbol: "W",
                elementCategory: "Transition metals",
                period: "6",
                protonNumber: "74",
                atomicWeight: "183,84",
                group: "VI.B",
                appearance: "grayish-white",
                electronConfiguration: "[Xe]4f14 5d4 6s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "3695 K (3422 °C, 6192 °F)",
                boilingPoint: "6203 K (5930 °C, 10706 °F)",
                density: "19,25 g/cm³"
            },
            atomicProperties: {
                oxidationStates: "-II, III, IV, VI, VI",
                electronegativity: "2,36"
            },
            miscellanea: {
                crystalStructure: "body-centered cubic",
                electricalResistivity: "52.8 nΩ·m",
                casNumber: "7440-33-7",
                yearOfDiscovery: "1783"
            }
        }
    }

    getFermium() {
        return {
            title: "Fermium",
            generalProperties: {
                symbol: "Fm",
                elementCategory: "Actinium",
                period: "7",
                protonNumber: "100",
                atomicWeight: "257,092",
                group: "III.B",
                appearance: "-",
                electronConfiguration: "[Rn]5f12 7s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "1800 K (1527 °C, 2781 °F)",
                boilingPoint: "-",
                density: "9.71 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "III",
                electronegativity: "1,3"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                electricalResistivity: "-",
                casNumber: "7440-72-4",
                yearOfDiscovery: "1952"
            }
        }
    }

    getManganese() {
        return {
            title: "Manganese",
            generalProperties: {
                symbol: "Mn",
                elementCategory: "Transition metals",
                period: "4",
                protonNumber: "25",
                atomicWeight: "54.9380454",
                group: "VII. B",
                appearance: "Silvery metallic",
                electronConfiguration: "[Ar] 3d5 4s2",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "1519 K (1246 °C, 2275 °F)",
                boilingPoint: "2334 K (2061 °C, 3742 °F)",
                density: "7.21 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "VII, VI, V, IV, III, II, I, -I, -II, -III",
                electronegativity: "1.54"
            },
            miscellanea: {
                crystalStructure: "body-centered cubic",
                electricalResistivity: "1,44 nΩ·m",
                casNumber: "7439-96-5",
                yearOfDiscovery: "1774"
            }
        }
    }

    getRadium() {
        return {
            title: "Radium",
            generalProperties: {
                symbol: "Ra",
                elementCategory: "Alkaline earth metals",
                period: "7",
                protonNumber: "88",
                atomicWeight: "226,0254",
                group: "II.A",
                appearance: "silvery white metallic",
                electronConfiguration: "[Rn]7s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "973 K (700 °C, 1292 °F)",
                boilingPoint: "2010 K (1737 °C, 3159 °F)",
                density: "5.5 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "II",
                electronegativity: "0,89"
            },
            miscellanea: {
                crystalStructure: "body-centered cubic",
                electricalResistivity: "1 µΩ·m",
                casNumber: "7440-14-4",
                yearOfDiscovery: "1898"
            }
        }
    }

    getArgon() {
        return {
            title: "Argon",
            generalProperties: {
                symbol: "Ar",
                elementCategory: "Rare gases",
                period: "3",
                protonNumber: "18",
                atomicWeight: "39.944",
                group: "VII.A",
                appearance: "Colorless gas",
                electronConfiguration: "[Ne] 3s2 3p6",
                radioactive: false
            },
            physicalProperties: {
                phase: "Gas",
                meltingPoint: "83.81 K (−189.34 °C, −308.81 °F)",
                boilingPoint: "87.302 K (−185.848 °C, −302.526 °F)",
                density: "0,0017838 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "0"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                casNumber: "7440-37-1",
                yearOfDiscovery: "1894"
            }
        }
    }

    getNeptunium() {
        return {
            title: "Neptunium",
            generalProperties: {
                symbol: "Np",
                elementCategory: "Actinium",
                period: "7",
                protonNumber: "93",
                atomicWeight: "230,048 2",
                group: "III.B",
                appearance: "silvery metallic",
                electronConfiguration: "[Rn]5f4 6d1 7s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "912±3 K (639±3 °C, 1182±5 °F)",
                boilingPoint: "4447 K (4174 °C, 7545 °F)",
                density: "19.38 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "III, IV, V, VI, VII",
                electronegativity: "1,36"
            },
            miscellanea: {
                crystalStructure: "orthorhombic",
                electricalResistivity: "1.220 µΩ·m",
                casNumber: "7439-99-8",
                yearOfDiscovery: "1940"
            }
        }
    }

    getNihonium() {
        return {
            title: "Nihonium",
            generalProperties: {
                symbol: "Nh",
                elementCategory: "Other metals",
                period: "7",
                protonNumber: "113",
                atomicWeight: "284,18",
                group: "III.A",
                appearance: "-",
                electronConfiguration: "[Rn] 5f14 6d10 7s2 7p1",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "700 K (430 °C, 810 °F)",
                boilingPoint: "1430 K (1130 °C, 2070 °F)",
                density: "16 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "-I, I, III, V",
                electronegativity: "-"
            },
            miscellanea: {
                crystalStructure: "-",
                electricalResistivity: "-",
                casNumber: "54084-70-7",
                yearOfDiscovery: "2004"
            }
        }
    }

    getProtactinium() {
        return {
            title: "Protactinium",
            generalProperties: {
                symbol: "Pa",
                elementCategory: "Actinium",
                period: "7",
                protonNumber: "91",
                atomicWeight: "231,0359",
                group: "V.B",
                appearance: "bright, silvery metallic luster",
                electronConfiguration: "[Rn]5f2 6d1 7s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "1841 K (1568 °C, 2854 °F)",
                boilingPoint: "4300 K (4027 °C, 7280 °F)",
                density: "15.37 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "II, III, IV, V",
                electronegativity: "1,5"
            },
            miscellanea: {
                crystalStructure: "tetragonal",
                electricalResistivity: "177 nΩ·m",
                casNumber: "7440-13-3",
                yearOfDiscovery: "1913"
            }
        }
    }

    getPotassium() {
        return {
            title: "Potassium",
            generalProperties: {
                symbol: "K",
                elementCategory: "Alkali metals",
                period: "4",
                protonNumber: "19",
                atomicWeight: "39.0983",
                group: "I.A",
                appearance: "Silvery gray",
                electronConfiguration: "[Ar] 4s1",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "336.7 K (63.5 °C, 146.3 °F)",
                boilingPoint: "1032 K (759 °C, 1398 °F)",
                density: "0.89 kg/dm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "I",
                electronegativity: "0.82"
            },
            miscellanea: {
                crystalStructure: "body-centered cubic",
                electricalResistivity: "0,61 µΩ·m",
                casNumber: "7440-09-7",
                yearOfDiscovery: "1807"
            }
        }
    }

    getIridium() {
        return {
            title: "Iridium",
            generalProperties: {
                symbol: "Ir",
                elementCategory: "Transition metals",
                period: "6",
                protonNumber: "77",
                atomicWeight: "192,217",
                group: "VIII.B",
                appearance: "silvery-white",
                electronConfiguration: "[Xe]4f14 5d7 6s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "2719 K (2446 °C, 4435 °F)",
                boilingPoint: "4403 K (4130 °C, 7466 °F)",
                density: "22,56 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "−III, −I, 0, I, II, III, IV, V, VI, VII, VIII, IX",
                electronegativity: "2,20"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                electricalResistivity: "47.1 nΩ·m",
                casNumber: "7439-88-5",
                yearOfDiscovery: "1804"
            }
        }
    }

    getGallium() {
        return {
            title: "Gallium",
            generalProperties: {
                symbol: "Ga",
                elementCategory: "Other metals",
                period: "4",
                protonNumber: "31",
                atomicWeight: "69.7234",
                group: "Group",
                appearance: "III.A",
                electronConfiguration: "[Ar] 3d10 4s2 4p1",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "302.9146 K (29.7646 °C, 85.5763 °F)",
                boilingPoint: "2673 K (2400 °C, 4352 °F)",
                density: "5.91 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "III, II, I",
                electronegativity: "1.81"
            },
            miscellanea: {
                crystalStructure: "orthorhombic",
                electricalResistivity: "0,5298 µΩ·m",
                casNumber: "7440-55-3",
                yearOfDiscovery: "1875"
            }
        }
    }

    getPlutonium() {
        return {
            title: "Plutonium",
            generalProperties: {
                symbol: "Pu",
                elementCategory: "Actinium",
                period: "7",
                protonNumber: "94",
                atomicWeight: "244,064",
                group: "III.B",
                appearance: "silvery-white",
                electronConfiguration: "[Rn]5f6 7s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "912.5 K (639.4 °C, 1182.9 °F)",
                boilingPoint: "3505 K (3228 °C, 5842 °F)",
                density: "19.816 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "III, IV, V, VI, VII, VIII",
                electronegativity: "1,28"
            },
            miscellanea: {
                crystalStructure: "monoclinic",
                electricalResistivity: "1.460 µΩ·m",
                casNumber: "7440-07-5",
                yearOfDiscovery: "1940"
            }
        }
    }

    getSodium() {
        return {
            title: "Sodium",
            generalProperties: {
                symbol: "Na",
                elementCategory: "Alkali metals",
                period: "3",
                protonNumber: "11",
                atomicWeight: "22.98976828",
                group: "I.A",
                appearance: "Silvery-white",
                electronConfiguration: "[Ne] 3s1",
                radioactive: false
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "370.944 K (97.794 °C, 208.029 °F)",
                boilingPoint: "1156.090 K (882.940 °C, 1621.292 °F)",
                density: "0.968 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "I",
                electronegativity: "0.93"
            },
            miscellanea: {
                crystalStructure: "body-centered cubic",
                electricalResistivity: "0,043 - 0,049 µΩ·m",
                casNumber: "7440-23-5",
                yearOfDiscovery: "1807"
            }
        }
    }

    getBohrium() {
        return {
            title: "Bohrium",
            generalProperties: {
                symbol: "Bh",
                elementCategory: "Transition metals",
                period: "7",
                protonNumber: "107",
                atomicWeight: "[270]",
                group: "VII.B",
                appearance: "-",
                electronConfiguration: "[Rn]5f14 6d5 7s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "-",
                boilingPoint: "-",
                density: "37.1 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "III, IV, V, VII",
                electronegativity: "-"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "-",
                casNumber: "54037-14-8",
                yearOfDiscovery: "1976"
            }
        }
    }

    getHydrogen() {
        return {
            title: "Hydrogen",
            generalProperties: {
                symbol: "H",
                elementCategory: "Nonmetals",
                period: "1",
                protonNumber: "1",
                atomicWeight: "1.00794",
                group: "I.A",
                appearance: "Colorless gas",
                electronConfiguration: "1s1",
                radioactive: false
            },
            physicalProperties: {
                phase: "Gas",
                meltingPoint: "13.99 K (-259.16 °C, -434.49 °F)",
                boilingPoint: "20.271 K (-252.879 °C, -423.182 °F)",
                density: "0.0899 kg/m<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "-I, I",
                electronegativity: "2.1"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "10<sup>-8</sup>Ωm",
                casNumber: "12385-13-6",
                yearOfDiscovery: "1766"
            }
        }
    }

    getEuropium() {
        return {
            title: "Europium",
            generalProperties: {
                symbol: "Eu",
                elementCategory: "Lanthanoid",
                period: "6",
                protonNumber: "63",
                atomicWeight: "151.964",
                group: "III.B",
                appearance: "Silvery-white",
                electronConfiguration: "[Xe] 4f7 6s2",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "1099 K (826 °C, 1519 °F)",
                boilingPoint: "1802 K (1529 °C, 2784 °F)",
                density: "5.264"
            },
            atomicProperties: {
                oxidationStates: "I, II, III",
                electronegativity: "1.2"
            },
            miscellanea: {
                crystalStructure: "body-centered cubic",
                electricalResistivity: "0.9 µΩ·m",
                casNumber: "7440-53-1",
                yearOfDiscovery: "1901"
            }
        }
    }

    getTennessine() {
        return {
            title: "Tennessine",
            generalProperties: {
                symbol: "Ts",
                elementCategory: "Halogens",
                period: "7",
                protonNumber: "117",
                atomicWeight: "292,20773",
                group: "VII.A",
                appearance: "-",
                electronConfiguration: "[Rn] 5f14 6d10 7s2 7p5",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "623–823 K (350–550 °C, 662–1022 °F)",
                boilingPoint: "883 K (610 °C, 1130 °F)",
                density: "7.1–7.3 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "-I, I, III, V",
                electronegativity: "-"
            },
            miscellanea: {
                crystalStructure: "-",
                electricalResistivity: "-",
                casNumber: "54101-14-3",
                yearOfDiscovery: "2010"
            }
        }
    }

    getAluminium() {
        return {
            title: "Aluminium",
            generalProperties: {
                symbol: "Al",
                elementCategory: "Other metals",
                period: "3",
                protonNumber: "13",
                atomicWeight: "26.9815386",
                group: "III.A",
                appearance: "Silvery gray metallic",
                electronConfiguration: "[Ne] 3s2 3p1",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "933.47 K (660.32 °C, 1220.58 °F)",
                boilingPoint: "2743 K (2470 °C, 4478 °F)",
                density: "Density"
            },
            atomicProperties: {
                oxidationStates: "III, II, I",
                electronegativity: "1.61"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                electricalResistivity: "28,74 nΩ·m",
                casNumber: "7429-90-5",
                yearOfDiscovery: "1825"
            }
        }
    }

    getCopernicium() {
        return {
            title: "Copernicium",
            generalProperties: {
                symbol: "Cn",
                elementCategory: "Transition metals",
                period: "7",
                protonNumber: "112",
                atomicWeight: "285,17",
                group: "II.B",
                appearance: "-",
                electronConfiguration: "[Rn] 5f14 6d10 7s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "-",
                boilingPoint: "-",
                density: "23.7 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "0, I, II",
                electronegativity: "-"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "-",
                casNumber: "54084-26-3",
                yearOfDiscovery: "1996"
            }
        }
    }

    getFrancium() {
        return {
            title: "Francium",
            generalProperties: {
                symbol: "Fr",
                elementCategory: "Alkali metals",
                period: "7",
                protonNumber: "87",
                atomicWeight: "233,019 8",
                group: "I.A",
                appearance: "-",
                electronConfiguration: "[Rn]7s1",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "300 K (30 °C, 80 °F)",
                boilingPoint: "950 K (680 °C, 1300 °F)",
                density: "2,29 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "I",
                electronegativity: "0.79"
            },
            miscellanea: {
                crystalStructure: "body-centered cubic",
                electricalResistivity: "3 µΩ·m",
                casNumber: "7440-73-5",
                yearOfDiscovery: "1939"
            }
        }
    }

    getNickel() {
        return {
            title: "Nickel",
            generalProperties: {
                symbol: "Ni",
                elementCategory: "Transition metals",
                period: "4",
                protonNumber: "28",
                atomicWeight: "58.6934",
                group: "VIII.B",
                appearance: "lustrous, metallic and silver with a gold tinge",
                electronConfiguration: "[Ar] 3d8 4s2",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "1728 K (1455 °C, 2651 °F)",
                boilingPoint: "3003 K (2730 °C, 4946 °F)",
                density: "8.908 kg/dm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "IV, III, II, I, -I",
                electronegativity: "1.91"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                electricalResistivity: "69,3 nΩ.m",
                casNumber: "7440-02-0",
                yearOfDiscovery: "1751"
            }
        }
    }

    getArsenic() {
        return {
            title: "Arsenic",
            generalProperties: {
                symbol: "As",
                elementCategory: "Metalloid",
                period: "4",
                protonNumber: "33",
                atomicWeight: "74.92160",
                group: "V.A",
                appearance: "Metallic grey",
                electronConfiguration: "[Ar] 3d10 4s2 4p3",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                density: "5.727 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "V, III, -III",
                electronegativity: "2.18"
            },
            miscellanea: {
                crystalStructure: "rhombohedral",
                electricalResistivity: "0,35 µΩ·m",
                casNumber: "7440-38-2",
                yearOfDiscovery: "≈ 1250"
            }
        }
    }

    getOsmium() {
        return {
            title: "Osmium",
            generalProperties: {
                symbol: "Os",
                elementCategory: "Transition metals",
                period: "6",
                protonNumber: "76",
                atomicWeight: "190,23",
                group: "VIII.B",
                appearance: "silvery",
                electronConfiguration: "[Xe]4f14 5d6 6s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "3306 K (3033 °C, 5491 °F)",
                boilingPoint: "5285 K (5012 °C, 9054 °F)",
                density: "22,59 g/cm³"
            },
            atomicProperties: {
                oxidationStates: "III, IV, VI, VIII",
                electronegativity: "2,2"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "81,2 nΩ·m",
                casNumber: "7440-04-2",
                yearOfDiscovery: "1803"
            }
        }
    }

    getMolybdenum() {
        return {
            title: "Molybdenum",
            generalProperties: {
                symbol: "Mo",
                elementCategory: "Transition",
                period: "5",
                protonNumber: "42",
                atomicWeight: "95.96",
                group: "VI.B",
                appearance: "Gray metallic",
                electronConfiguration: "[Kr] 4d5 5s1",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "2896 K (2623 °C, 4753 °F)",
                boilingPoint: "4912 K (4639 °C, 8382 °F)",
                density: "10.28 g.cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "-II, -I, I, II, III, IV, V, VI",
                electronegativity: "2.16"
            },
            miscellanea: {
                crystalStructure: "body-centered cubic",
                electricalResistivity: "53.4 nΩ·m",
                casNumber: "7439-98-7",
                yearOfDiscovery: "1778"
            }
        }
    }

    getTantalum() {
        return {
            title: "Tantalum",
            generalProperties: {
                symbol: "Ta",
                elementCategory: "Transition metals",
                period: "6",
                protonNumber: "73",
                atomicWeight: "180,9479",
                group: "V.B",
                appearance: "gray blue",
                electronConfiguration: "[Xe]4f14 5d3 6s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "3290 K (3017 °C, 5463 °F)",
                boilingPoint: "5731 K (5458 °C, 9856 °F)",
                density: "16,69 g/cm³"
            },
            atomicProperties: {
                oxidationStates: "II, III, IV, V",
                electronegativity: "1,5"
            },
            miscellanea: {
                crystalStructure: "body-centered cubic",
                electricalResistivity: "131 nΩ·m",
                casNumber: "7440-25-7",
                yearOfDiscovery: "1802"
            }
        }
    }

    getPhosphorus() {
        return {
            title: "Phosphorus",
            generalProperties: {
                symbol: "P",
                elementCategory: "Non metals",
                period: "3",
                protonNumber: "15",
                atomicWeight: "30.973",
                group: "V.A",
                appearance: "colourless, waxy white, yellow, scarlet, red, violet, black",
                electronConfiguration: "[Ne] 3s2 3p3",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "-",
                boilingPoint: "-",
                density: "1.823 g/cm<sup>3</sup> (white), 2.34 g/cm<sup>3</sup> (red), 2.69 g/cm<sup>3</sup> (black)"
            },
            atomicProperties: {
                oxidationStates: "V, IV, III, II, I, -II, -III",
                electronegativity: "2.19"
            },
            miscellanea: {
                crystalStructure: "body-centered cubic",
                electricalResistivity: "-",
                casNumber: "7723-14-0",
                yearOfDiscovery: "1669"
            }
        }
    }

    getIndium() {
        return {
            title: "Indium",
            generalProperties: {
                symbol: "In",
                elementCategory: "Other metals",
                period: "5",
                protonNumber: "49",
                atomicWeight: "114.818",
                group: "III.A",
                appearance: "Silvery lustrous gray",
                electronConfiguration: "[Kr] 4d10 5s2 5p1",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "429.7485 K (156.5985 °C, 313.8773 °F)",
                boilingPoint: "2345 K (2072 °C, 3762 °F)",
                density: "7.31 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "I, II, III",
                electronegativity: "1.78"
            },
            miscellanea: {
                crystalStructure: "tetragonal",
                electricalResistivity: "83.7 nΩ·m",
                casNumber: "7440-74-6",
                yearOfDiscovery: "1863"
            }
        }
    }

    getChromium() {
        return {
            title: "Chromium",
            generalProperties: {
                symbol: "Cr",
                elementCategory: "Transition metals",
                period: "4",
                protonNumber: "24",
                atomicWeight: "51.9961",
                group: "VI.B",
                appearance: "Silvery metallic",
                electronConfiguration: "[Ar] 3d5 4s1",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "2180 K (1907 °C, 3465 °F)",
                boilingPoint: "2944 K (2671 °C, 4840 °F)",
                density: "7.15 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "VI, V, IV, III, II, I, -I, -II",
                electronegativity: "1.66"
            },
            miscellanea: {
                crystalStructure: "Body-centered cubic",
                electricalResistivity: "125 nΩ·m",
                casNumber: "7440-47-3",
                yearOfDiscovery: "1797"
            }
        }
    }

    getThulium() {
        return {
            title: "Thulium",
            generalProperties: {
                symbol: "Tm",
                elementCategory: "Lanthanoid",
                period: "6",
                protonNumber: "69",
                atomicWeight: "168,93421",
                group: "III.B",
                appearance: "silvery gray",
                electronConfiguration: "[Xe]4f13 6s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "1818 K (1545 °C, 2813 °F)",
                boilingPoint: "2223 K (1950 °C, 3542 °F)",
                density: "9,32 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "II, III",
                electronegativity: "1,25"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "676 nΩ·m",
                casNumber: "7440-30-4",
                yearOfDiscovery: "1879"
            }
        }
    }

    getTechnetium() {
        return {
            title: "Technetium",
            generalProperties: {
                symbol: "Tc",
                elementCategory: "Transition metals",
                period: "5",
                protonNumber: "43",
                atomicWeight: "98",
                group: "VII.B",
                appearance: "Shiny gray metal",
                electronConfiguration: "[Kr] 4d5 5s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "2430 K (2157 °C, 3915 °F)",
                boilingPoint: "4538 K (4265 °C, 7709 °F)",
                density: "11 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "-I, I, II, III, IV, V, VI, VII",
                electronegativity: "1.9"
            },
            miscellanea: {
                crystalStructure: "body-centered cubic",
                casNumber: "7440-26-8",
                yearOfDiscovery: "1937"
            }
        }
    }

    getRoentgenium() {
        return {
            title: "Roentgenium",
            generalProperties: {
                symbol: "Rg",
                elementCategory: "Transition metals",
                period: "7",
                protonNumber: "111",
                atomicWeight: "280,16",
                group: "I.B",
                appearance: "-",
                electronConfiguration: "[Rn] 5f14 6d10 7s1",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "-",
                boilingPoint: "-",
                density: "28.7 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "-I, I, III, V",
                electronegativity: "-"
            },
            miscellanea: {
                crystalStructure: "body-centered cubic",
                electricalResistivity: "-",
                casNumber: "54386-24-2",
                yearOfDiscovery: "1996"
            }
        }
    }

    getLead() {
        return {
            title: "Lead",
            generalProperties: {
                symbol: "Pb",
                elementCategory: "Other metals",
                period: "6",
                protonNumber: "82",
                atomicWeight: "207,2",
                group: "IV.A",
                appearance: "metallic grey",
                electronConfiguration: "[Xe]4f14 5d10 6s2 6p2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "600.61 K (327.46 °C, 621.43 °F)",
                boilingPoint: "2022 K (1749 °C, 3180 °F)",
                density: "11.34 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "-IV, -II, -I, I, II, III, IV",
                electronegativity: "2,33"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                electricalResistivity: "208 nΩ·m",
                casNumber: "7439-92-1",
                yearOfDiscovery: "-"
            }
        }
    }

    getSeaborgium() {
        return {
            title: "Seaborgium",
            generalProperties: {
                symbol: "Sg",
                elementCategory: "Transition metals",
                period: "7",
                protonNumber: "106",
                atomicWeight: "271,13",
                group: "VI.B",
                appearance: "-",
                electronConfiguration: "[Rn]5f14 6d4 7s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "-",
                boilingPoint: "-",
                density: "35.0 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "0, III, IV, V, VI",
                electronegativity: "-"
            },
            miscellanea: {
                crystalStructure: "body-centered cubic",
                electricalResistivity: "-",
                casNumber: "54038-81-2",
                yearOfDiscovery: "1974"
            }
        }
    }

    getOxygen() {
        return {
            title: "Oxygen",
            generalProperties: {
                symbol: "O",
                elementCategory: "Non metals",
                period: "2",
                protonNumber: "8",
                atomicWeight: "15.9994",
                group: "VI.A",
                appearance: "Colorless gas",
                electronConfiguration: "[He] 2s2 2p4",
                radioactive: false
            },
            physicalProperties: {
                phase: "gas",
                meltingPoint: "54.36 K (−218.79 °C, −361.82 °F)",
                boilingPoint: "90.188 K (−182.962 °C, −297.332 °F)",
                density: "1.429 kg/m<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "II, -I, -II",
                electronegativity: "3.44"
            },
            miscellanea: {
                crystalStructure: "cubic",
                electricalResistivity: "-",
                casNumber: "7782-44-7",
                yearOfDiscovery: "1774"
            }
        }
    }

    getBromine() {
        return {
            title: "Bromine",
            generalProperties: {
                symbol: "Br",
                elementCategory: "Halogens",
                period: "4",
                protonNumber: "35",
                atomicWeight: "79.904",
                group: "VII.A",
                appearance: "Reddish brown",
                electronConfiguration: "[Ar] 3d10 4s2 4p5",
                radioactive: false
            },
            physicalProperties: {
                phase: "Liquid",
                meltingPoint: "265.8 K (−7.2 °C, 19 °F)",
                boilingPoint: "332.0 K (58.8 °C, 137.8 °F)",
                density: "3.1028 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "VII, V, IV, III, I, -I",
                electronegativity: "2.96"
            },
            miscellanea: {
                crystalStructure: "orthorhombic",
                electricalResistivity: "7,8×10<sup>10</sup> Ω·m",
                casNumber: "7726-95-6",
                yearOfDiscovery: "1826"
            }
        }
    }

    getCadmium() {
        return {
            title: "Cadmium",
            generalProperties: {
                symbol: "Cd",
                elementCategory: "Transition metals",
                period: "5",
                protonNumber: "48",
                atomicWeight: "112.411",
                group: "II.B",
                appearance: "Silvery bluish-gray metallic",
                electronConfiguration: "[Kr] 4d10 5s2",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "594.22 K (321.07 °C, 609.93 °F)",
                boilingPoint: "1040 K (767 °C, 1413 °F)",
                density: "8650 kg/m<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "I, II",
                electronegativity: "1.69"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "72.7 nΩ·m",
                casNumber: "7440-43-9",
                yearOfDiscovery: "1817"
            }
        }
    }

    getRubidium() {
        return {
            title: "Rubidium",
            generalProperties: {
                symbol: "Rb",
                elementCategory: "Alkali metals",
                period: "5",
                protonNumber: "37",
                atomicWeight: "85.4678",
                group: "I.A",
                appearance: "Grey white",
                electronConfiguration: "[Kr] 5s1",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "312.45 K (39.30 °C, 102.74 °F)",
                boilingPoint: "961 K (688 °C, 1270 °F)",
                density: "1.532 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "I",
                electronegativity: "0.82"
            },
            miscellanea: {
                crystalStructure: "body-centered cubic",
                electricalResistivity: "128 nΩ·m",
                casNumber: "7440-17-7",
                yearOfDiscovery: "1861"
            }
        }
    }

    getCobalt() {
        return {
            title: "Cobalt",
            generalProperties: {
                symbol: "Co",
                elementCategory: "Transition metals",
                period: "4",
                protonNumber: "27",
                atomicWeight: "58.933195",
                group: "VIII.B",
                appearance: "Hard lustrous bluish gray metal",
                electronConfiguration: "[Ar] 3d7 4s2",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "1768 K (1495 °C, 2723 °F)",
                boilingPoint: "3200 K (2927 °C, 5301 °F)",
                density: "8,90 kg/dm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "V, IV, III, II, I, -I",
                electronegativity: "1.88"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "62,4 nΩ·m",
                casNumber: "7440-48-4",
                yearOfDiscovery: "1735"
            }
        }
    }

    getTitanium() {
        return {
            title: "Titanium",
            generalProperties: {
                symbol: "Ti",
                elementCategory: "Transition metals",
                period: "4",
                protonNumber: "22",
                atomicWeight: "47.867",
                group: "IV.B",
                appearance: "Silvery grey-white metallic",
                electronConfiguration: "[Ar] 3d2 4s2",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "1941 K (1668 °C, 3034 °F)",
                boilingPoint: "3560 K (3287 °C, 5949 °F)",
                density: "4.506 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "I, II, III, IV",
                electronegativity: "1.54"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "420 nΩ·m",
                casNumber: "7440-32-6",
                yearOfDiscovery: "1668"
            }
        }
    }

    getSilicon() {
        return {
            title: "Silicon",
            generalProperties: {
                symbol: "Si",
                elementCategory: "Metalloid",
                period: "3",
                protonNumber: "14",
                atomicWeight: "28.0855",
                group: "IV.A",
                appearance: "Crystalline",
                electronConfiguration: "[Ne] 3s2 3p2",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "1687 K (1414 °C, 2577 °F)",
                boilingPoint: "3538 K (3265 °C, 5909 °F)",
                density: "2.3290 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "IV, III, II, I, -I, -II, -III, -IV",
                electronegativity: "1.9"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                electricalResistivity: "2300 Ω·m",
                casNumber: "7440-21-3",
                yearOfDiscovery: "1822"
            }
        }
    }

    getNitrogen() {
        return {
            title: "Nitrogen",
            generalProperties: {
                symbol: "N",
                elementCategory: "Non metals",
                period: "2",
                protonNumber: "7",
                atomicWeight: "14.0067",
                group: "V.A",
                appearance: "colorless gas, liquid, solid",
                electronConfiguration: "[He] 2s2 2p3",
                radioactive: false
            },
            physicalProperties: {
                phase: "gas",
                meltingPoint: "63.15 K (−210.00 °C, −346.00 °F)",
                boilingPoint: "77.355 K (−195.795 °C, −320.431 °F)",
                density: "1.2506 kg/m<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "V, IV, III, II. I, -I, -II, -III",
                electronegativity: "3.1"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "-",
                casNumber: "17778-88-0",
                yearOfDiscovery: "1772"
            }
        }
    }

    getLawrencium() {
        return {
            title: "Lawrencium",
            generalProperties: {
                symbol: "Lr",
                elementCategory: "Actinium",
                period: "7",
                protonNumber: "103",
                atomicWeight: "262,11",
                group: "III.B",
                appearance: "silvery",
                electronConfiguration: "[Rn]5f14 6d1 7s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "1900 K (1627 °C, 2961 °F)",
                boilingPoint: "-",
                density: "~15.6–16.6 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "III",
                electronegativity: "1,3"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "-",
                casNumber: "22537-19-5",
                yearOfDiscovery: "1961"
            }
        }
    }

    getRuthenium() {
        return {
            title: "Ruthenium",
            generalProperties: {
                symbol: "Ru",
                elementCategory: "Transition metals",
                period: "5",
                protonNumber: "44",
                atomicWeight: "101.07",
                group: "VIII.B",
                appearance: "Silvery white metallic",
                electronConfiguration: "[Kr] 4d7 5s1",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "2607 K (2334 °C, 4233 °F)",
                boilingPoint: "4423 K (4150 °C, 7502 °F)",
                density: "12.41 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "-II, II, III, IV, VI, VII, VIII",
                electronegativity: "2.2"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "71 nΩ·m",
                casNumber: "7440-18-8",
                yearOfDiscovery: "1844"
            }
        }
    }

    getBoron() {
        return {
            title: "Boron",
            generalProperties: {
                symbol: "B",
                elementCategory: "Metalloid",
                period: "2",
                protonNumber: "5",
                atomicWeight: "10.811",
                group: "III.A",
                appearance: "Black-brown",
                electronConfiguration: "[He] 2s2 2p1",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "2349 K (2076 °C, 3769 °F)",
                boilingPoint: "4200 K (3927 °C, 7101 °F)",
                density: "Density"
            },
            atomicProperties: {
                oxidationStates: "III, II, I",
                electronegativity: "2.04"
            },
            miscellanea: {
                crystalStructure: "rhombohedral",
                electricalResistivity: "10<sup>6</sup>Ωm",
                casNumber: "7440-42-8",
                yearOfDiscovery: "1808"
            }
        }
    }

    getCarbon() {
        return {
            title: "Carbon",
            generalProperties: {
                symbol: "C",
                elementCategory: "Non metals",
                period: "2",
                protonNumber: "6",
                atomicWeight: "12.0107",
                group: "IV.A",
                appearance: "graphite: black, diamond: clear",
                electronConfiguration: "[He] 2s2 2p2",
                radioactive: false
            },
            physicalProperties: {
                phase: "solid",
                density: "graphite: 2270 kg/m<sup>3</sup>, diamond: 3513 kg/m<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "IV, II, I, 0, -II, -IV",
                electronegativity: "2.5"
            },
            miscellanea: {
                crystalStructure: "graphite: hexagonal, diamond: face-centered cubic",
                electricalResistivity: "graphite: 10<sup>-4</sup> Ωm, diamond: 10<sup>3</sup> Ωm",
                casNumber: "7440-44-0"
            }
        }
    }

    getActinium() {
        return {
            title: "Actinium",
            generalProperties: {
                symbol: "Ac",
                elementCategory: "Transition metals",
                period: "7",
                protonNumber: "89",
                atomicWeight: "227,027 8",
                group: "III.B",
                appearance: "silvery-white",
                electronConfiguration: "[Rn]6d1 7s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "1500 K (1227 °C, 2240 °F)",
                boilingPoint: "3500±300 K (3200±300 °C, 5800±500 °F)",
                density: "10,062 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "III",
                electronegativity: "1,1"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                electricalResistivity: "-",
                casNumber: "7440-34-8",
                yearOfDiscovery: "1899"
            }
        }
    }

    getLivermorium() {
        return {
            title: "Livermorium",
            generalProperties: {
                symbol: "Lv",
                elementCategory: "Other metals",
                period: "7",
                protonNumber: "116",
                atomicWeight: "293",
                group: "VI.A",
                appearance: "-",
                electronConfiguration: "[Rn] 5f14 6d10 7s2 7p4",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "637–780 K (364–507 °C, 687–944 °F)",
                boilingPoint: "1035–1135 K (762–862 °C, 1403–1583 °F)",
                density: "12.9 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "-II, II, IV",
                electronegativity: "-"
            },
            miscellanea: {
                crystalStructure: "-",
                electricalResistivity: "-",
                casNumber: "54100-71-9",
                yearOfDiscovery: "2000"
            }
        }
    }

    getThallium() {
        return {
            title: "Thallium",
            generalProperties: {
                symbol: "Tl",
                elementCategory: "Other metals",
                period: "6",
                protonNumber: "81",
                atomicWeight: "204,3833",
                group: "III.A",
                appearance: "silvery-white",
                electronConfiguration: "[Xe]4f14 5d10 6s2 6p1",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "577 K (304 °C, 579 °F)",
                boilingPoint: "1746 K (1473 °C, 2683 °F)",
                density: "11.85 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "-V, -II, -I, I, II, III",
                electronegativity: "1,62"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "0.18 µΩ·m",
                casNumber: "7440-28-0",
                yearOfDiscovery: "1861"
            }
        }
    }

    getSamarium() {
        return {
            title: "Samarium",
            generalProperties: {
                symbol: "Sm",
                elementCategory: "Lanthanoid",
                period: "6",
                protonNumber: "62",
                atomicWeight: "150.36",
                group: "III.B",
                appearance: "Silvery-white",
                electronConfiguration: "[Xe] 4f6 6s2",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "1345 K (1072 °C, 1962 °F)",
                boilingPoint: "2173 K (1900 °C, 3452 °F)",
                density: "7.52"
            },
            atomicProperties: {
                oxidationStates: "I, II, III, IV",
                electronegativity: "1.17"
            },
            miscellanea: {
                crystalStructure: "rhombohedral",
                electricalResistivity: "0.94 µΩ·m",
                casNumber: "7440-19-9",
                yearOfDiscovery: "1879"
            }
        }
    }

    getTellurium() {
        return {
            title: "Tellurium",
            generalProperties: {
                symbol: "Te",
                elementCategory: "Metalloid",
                period: "5",
                protonNumber: "52",
                atomicWeight: "127.60",
                group: "VI.A",
                appearance: "Silvery lustrous gray",
                electronConfiguration: "[Kr] 4d10 5s2 5p4",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "722.66 K (449.51 °C, 841.12 °F)",
                boilingPoint: "1261 K (988 °C, 1810 °F)",
                density: "6.24 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "-II, II, IV, VI",
                electronegativity: "2.1"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "2 x 10<sup>5</sup>Ω·m",
                casNumber: "1394-80-9",
                yearOfDiscovery: "1782"
            }
        }
    }

    getPalladium() {
        return {
            title: "Palladium",
            generalProperties: {
                symbol: "Pd",
                elementCategory: "Transition metals",
                period: "5",
                protonNumber: "46",
                atomicWeight: "106.42",
                group: "VIII.B",
                appearance: "Silvery white",
                electronConfiguration: "[Kr] 4d10",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "1828.05 K (1554.9 °C, 2830.82 °F)",
                boilingPoint: "3236 K (2963 °C, 5365 °F)",
                density: "12.023 Kg/dm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "0, I, II, IV, VI",
                electronegativity: "2.20"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                electricalResistivity: "105.4 nΩ·m",
                casNumber: "7440-05-3",
                yearOfDiscovery: "1803"
            }
        }
    }

    getTin() {
        return {
            title: "Tin",
            generalProperties: {
                symbol: "Sn",
                elementCategory: "Other metals",
                period: "5",
                protonNumber: "50",
                atomicWeight: "118.710",
                group: "IV.A",
                appearance: "Silvery white",
                electronConfiguration: "[Kr] 4d10 5s2 5p2",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "505.08 K (231.93 °C, 449.47 °F)",
                boilingPoint: "2875 K (2602 °C, 4716 °F)",
                density: "white, β: 7.265 g/cm<sup>3</sup>, gray, α: 5.769 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "-IV, II, IV",
                electronegativity: "1.96"
            },
            miscellanea: {
                crystalStructure: "tetragonal",
                electricalResistivity: "115 nΩ·m",
                casNumber: "7440-31-5"
            }
        }
    }

    getChlorine() {
        return {
            title: "Chlorine",
            generalProperties: {
                symbol: "Cl",
                elementCategory: "Halogens",
                period: "3",
                protonNumber: "17",
                atomicWeight: "35.453",
                group: "VII.A",
                appearance: "pale yellow-green gas",
                electronConfiguration: "[Ne] 3s2 3p5",
                radioactive: false
            },
            physicalProperties: {
                phase: "Gas",
                meltingPoint: "171.6 K (−101.5 °C, −150.7 °F)",
                boilingPoint: "239.11 K (−34.04 °C, −29.27 °F)",
                density: "0.0032 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "VII, VI, V, IV, III, II, I, -I",
                electronegativity: "3.16"
            },
            miscellanea: {
                crystalStructure: "orthorhombic",
                electricalResistivity: ">10 Ω·m",
                casNumber: "7782-50-5",
                yearOfDiscovery: "1774"
            }
        }
    }

    getVanadium() {
        return {
            title: "Vanadium",
            generalProperties: {
                symbol: "V",
                elementCategory: "Transition metals",
                period: "4",
                protonNumber: "23",
                atomicWeight: "50.9415",
                group: "V.B",
                appearance: "Blue-silvery-grey metal",
                electronConfiguration: "[Ar] 3d3 4s2",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "2183 K (1910 °C, 3470 °F)",
                boilingPoint: "3680 K (3407 °C, 6165 °F)",
                density: "6 kg/dm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "V, IV, III, II, I, -I",
                electronegativity: "1.63"
            },
            miscellanea: {
                crystalStructure: "body-centered cubic",
                electricalResistivity: "197 nΩ·m",
                casNumber: "7440-62-2",
                yearOfDiscovery: "1830"
            }
        }
    }

    getZinc() {
        return {
            title: "Zinc",
            generalProperties: {
                symbol: "Zn",
                elementCategory: "Transition metals",
                period: "4",
                protonNumber: "30",
                atomicWeight: "65.409",
                group: "II.B",
                appearance: "Silvery gray",
                electronConfiguration: "[Ar] 3d10 4s2",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "692.68 K (419.53 °C, 787.15 °F)",
                boilingPoint: "1180 K (907 °C, 1665 °F)",
                density: "7.14 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "&ensp;",
                electronegativity: "1.65"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "59,0 nΩ·m",
                casNumber: "7440-66-6",
                yearOfDiscovery: "≈ 1500"
            }
        }
    }

    getRutherfordium() {
        return {
            title: "Rutherfordium",
            generalProperties: {
                symbol: "Rf",
                elementCategory: "Transition metals",
                period: "7",
                protonNumber: "104",
                atomicWeight: "265,12",
                group: "III.B",
                appearance: "-",
                electronConfiguration: "[Rn]5f14 6d2 7s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "2400 K (2100 °C, 3800 °F)",
                boilingPoint: "5800 K (5500 °C, 9900 °F)",
                density: "23.2 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "II, III, IV",
                electronegativity: "-"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "-",
                casNumber: "53850-36-5",
                yearOfDiscovery: "1969"
            }
        }
    }

    getDubnium() {
        return {
            title: "Dubnium",
            generalProperties: {
                symbol: "Db",
                elementCategory: "Transition metals",
                period: "7",
                protonNumber: "105",
                atomicWeight: "268,13",
                group: "V.B",
                appearance: "-",
                electronConfiguration: "[Rn]5f14 6d3 7s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "-",
                boilingPoint: "-",
                density: "29.3 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "III, IV, V",
                electronegativity: "-"
            },
            miscellanea: {
                crystalStructure: "body-centered cubic",
                electricalResistivity: "-",
                casNumber: "53850-35-4",
                yearOfDiscovery: "1970"
            }
        }
    }

    getYttrium() {
        return {
            title: "Yttrium",
            generalProperties: {
                symbol: "Y",
                elementCategory: "Transition metals",
                period: "5",
                protonNumber: "39",
                atomicWeight: "88.90585",
                group: "III.B",
                appearance: "Silvery-white",
                electronConfiguration: "[Kr] 4d1 5s2",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "1799 K (1526 °C, 2779 °F)",
                boilingPoint: "3203 K (2930 °C, 5306 °F)",
                density: "4.472 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "III, II, I",
                electronegativity: "1.22"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "596 nΩ·m",
                casNumber: "7440-65-5",
                yearOfDiscovery: "1794"
            }
        }
    }

    getIodine() {
        return {
            title: "Iodine",
            generalProperties: {
                symbol: "I",
                elementCategory: "Halogens",
                period: "5",
                protonNumber: "53",
                atomicWeight: "126.90447",
                group: "VII.A",
                appearance: "Lustrous metallic gray",
                electronConfiguration: "[Kr] 4d10 5s2 5p5",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "386.85 K (113.7 °C, 236.66 °F)",
                boilingPoint: "457.4 K (184.3 °C, 363.7 °F)",
                density: "4933 kg/m<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "-I, I, III, V, VII",
                electronegativity: "2.2"
            },
            miscellanea: {
                crystalStructure: "orthorhombic",
                electricalResistivity: "1.3 x 10<sup>7</sup> Ω·m",
                casNumber: "7553-56-2",
                yearOfDiscovery: "1811"
            }
        }
    }

    getMercury() {
        return {
            title: "Mercury",
            generalProperties: {
                symbol: "Hg",
                elementCategory: "Transition metals",
                period: "6",
                protonNumber: "80",
                atomicWeight: "200,59",
                group: "II.B",
                appearance: "silvery",
                electronConfiguration: "[Xe]4f14 5d10 6s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "liquid",
                meltingPoint: "234.3210 K (−38.8290 °C, −37.8922 °F)",
                boilingPoint: "629.88 K (356.73 °C, 674.11 °F)",
                density: "13,534 g/cm³"
            },
            atomicProperties: {
                oxidationStates: "-II, I, II",
                electronegativity: "2,00"
            },
            miscellanea: {
                crystalStructure: "​rhombohedral",
                electricalResistivity: "961 nΩ·m",
                casNumber: "7439-97-6",
                yearOfDiscovery: "-"
            }
        }
    }

    getThorium() {
        return {
            title: "Thorium",
            generalProperties: {
                symbol: "Th",
                elementCategory: "Actinium",
                period: "7",
                protonNumber: "90",
                atomicWeight: "232,0381",
                group: "IV.B",
                appearance: "silvery",
                electronConfiguration: "[Rn]6d2 7s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "2023 K (1750 °C, 3182 °F)",
                boilingPoint: "5061 K (4788 °C, 8650 °F)",
                density: "11,724 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "II, II, IV",
                electronegativity: "1,3"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                electricalResistivity: "157 nΩ·m",
                casNumber: "7440-29-1",
                yearOfDiscovery: "1828"
            }
        }
    }

    getPromethium() {
        return {
            title: "Promethium",
            generalProperties: {
                symbol: "Pm",
                elementCategory: "Lanthanoid",
                period: "6",
                protonNumber: "61",
                atomicWeight: "145",
                group: "III.B",
                appearance: "Metallic",
                electronConfiguration: "[Xe] 4f5 6s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "1315 K (1042 °C, 1908 °F)",
                boilingPoint: "3273 K (3000 °C, 5432 °F)",
                density: "7.26 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "II, III",
                electronegativity: "1.13"
            },
            miscellanea: {
                crystalStructure: "double hexagonal close-package",
                electricalResistivity: "o.75 µΩ·m",
                casNumber: "7440-12-2",
                yearOfDiscovery: "1945"
            }
        }
    }

    getTerbium() {
        return {
            title: "Terbium",
            generalProperties: {
                symbol: "Tb",
                elementCategory: "Lanthanoid",
                period: "6",
                protonNumber: "65",
                atomicWeight: "158,92535",
                group: "III.B",
                appearance: "silvery-white",
                electronConfiguration: "[Xe]4f9 6s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "1629 K (1356 °C, 2473 °F)",
                boilingPoint: "3396 K (3123 °C, 5653 °F)",
                density: "8,23 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "I, II, III, IV",
                electronegativity: "1,2"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "1.150 µΩ·m",
                casNumber: "7440-27-9",
                yearOfDiscovery: "1843"
            }
        }
    }

    getMeitnerium() {
        return {
            title: "Meitnerium",
            generalProperties: {
                symbol: "Mt",
                elementCategory: "Transition metals",
                period: "7",
                protonNumber: "109",
                atomicWeight: "276,15",
                group: "VIII.B",
                appearance: "-",
                electronConfiguration: "[Rn]5f14 6d7 7s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "-",
                boilingPoint: "-",
                density: "37.4 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "I, III, IV, VI, VIII, IX",
                electronegativity: "-"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                electricalResistivity: "-",
                casNumber: "54038-01-6",
                yearOfDiscovery: "1982"
            }
        }
    }

    getAmericium() {
        return {
            title: "Americium",
            generalProperties: {
                symbol: "Am",
                elementCategory: "Actinium",
                period: "7",
                protonNumber: "95",
                atomicWeight: "241,056 7",
                group: "III.B",
                appearance: "silvery-white",
                electronConfiguration: "[Rn]5f7 7s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "1449 K (1176 °C, 2149 °F)",
                boilingPoint: "2880 K (2607 °C, 4725 °F)",
                density: "12 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "II, III, IV, V, VI",
                electronegativity: "1,3"
            },
            miscellanea: {
                crystalStructure: "double hexagonal close-packed",
                electricalResistivity: "0.69 µΩ·m[",
                casNumber: "7440-35-9",
                yearOfDiscovery: "1944"
            }
        }
    }

    getPolonium() {
        return {
            title: "Polonium",
            generalProperties: {
                symbol: "Po",
                elementCategory: "Metalloid",
                period: "6",
                protonNumber: "84",
                atomicWeight: "(209)",
                group: "VI.A",
                appearance: "silvery",
                electronConfiguration: "[Xe]4f14 5d10 6s2 6p4",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "527 K (254 °C, 489 °F)",
                boilingPoint: "1235 K (962 °C, 1764 °F)",
                density: "α: 9.196 g/cm3"
            },
            atomicProperties: {
                oxidationStates: "<h3> &ensp;</h3>",
                electronegativity: "-II, II, IV, V, VI"
            },
            miscellanea: {
                crystalStructure: "<h3> &ensp;</h3>",
                electricalResistivity: "α: cubic",
                casNumber: "β: ​rhombohedral",
                yearOfDiscovery: "α: 0.40 µΩ·m"
            }
        }
    }

    getHassium() {
        return {
            title: "Hassium",
            generalProperties: {
                symbol: "Hs",
                elementCategory: "Transition metals",
                period: "7",
                protonNumber: "108",
                atomicWeight: "277,15",
                group: "VIII.B",
                appearance: "silvery",
                electronConfiguration: "[Rn]5f14 6d6 7s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "-",
                boilingPoint: "-",
                density: "41 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "II, III, IV, V, VI, VIII",
                electronegativity: "-"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "-",
                casNumber: "54037-57-9",
                yearOfDiscovery: "1984"
            }
        }
    }

    getBeryllium() {
        return {
            title: "Beryllium",
            generalProperties: {
                symbol: "Be",
                elementCategory: "Alkaline earth metals",
                period: "2",
                protonNumber: "4",
                atomicWeight: "9.012182",
                group: "II.A",
                appearance: "White-gray metallic",
                electronConfiguration: "[He]2s2",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "1560K (1287 °C, 2349 °F",
                boilingPoint: "2742 K (2469 °C, 4476 °F)",
                density: "1 848 kg/m<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "2, 1",
                electronegativity: "1.57"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "36 nΩ·m",
                casNumber: "7440-41-7",
                yearOfDiscovery: "1978"
            }
        }
    }

    getIron() {
        return {
            title: "Iron",
            generalProperties: {
                symbol: "Fe",
                elementCategory: "Transition metals",
                period: "4",
                protonNumber: "26",
                atomicWeight: "55.845",
                group: "VIII.B",
                appearance: "Lustrous metallic with a grayish tinge",
                electronConfiguration: "[Ar] 3d6 4s2",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "1811 K (1538 °C, 2800 °F)",
                boilingPoint: "3134 K (2862 °C, 5182 °F)",
                density: "7.86 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "VI, IV, III, II, -II",
                electronegativity: "1.83"
            },
            miscellanea: {
                crystalStructure: "body-centered cubic",
                electricalResistivity: "9,71 μΩm"
            }
        }
    }

    getLanthanum() {
        return {
            title: "Lanthanum",
            generalProperties: {
                symbol: "La",
                elementCategory: "Transition metals",
                period: "6",
                protonNumber: "57",
                atomicWeight: "138.905",
                group: "III.B",
                appearance: "Silvery white",
                electronConfiguration: "[Xe] 5d1 6s2",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "1193 K (920 °C, 1688 °F)",
                boilingPoint: "3737 K (3464 °C, 6267 °F)",
                density: "6.17 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "I, II, III",
                electronegativity: "1.1"
            },
            miscellanea: {
                crystalStructure: "double hexagonal close-package",
                electricalResistivity: "615 nΩ·m",
                casNumber: "7439-91-0",
                yearOfDiscovery: "1839"
            }
        }
    }

    getPraseodymium() {
        return {
            title: "Praseodymium",
            generalProperties: {
                symbol: "Pr",
                elementCategory: "Lanthanoid",
                period: "6",
                protonNumber: "59",
                atomicWeight: "140.90765",
                group: "III.B",
                appearance: "Grayish-white",
                electronConfiguration: "[Xe] 4f3 4d1 5s2",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "1208 K (935 °C, 1715 °F)",
                boilingPoint: "3403 K (3130 °C, 5666 °F)",
                density: "6.77 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "II, III, IV, V",
                electronegativity: "1.13"
            },
            miscellanea: {
                crystalStructure: "double hexagonal close-package",
                casNumber: "7440-10-0",
                yearOfDiscovery: "1885"
            }
        }
    }

    getSelenium() {
        return {
            title: "Selenium",
            generalProperties: {
                symbol: "Se",
                elementCategory: "Non metals",
                period: "4",
                protonNumber: "34",
                atomicWeight: "78.94",
                group: "VI.A",
                appearance: "Black, red and gray allotropes",
                electronConfiguration: "[Ar] 3d10 4s2 4p4",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "494 K (221 °C, 430 °F)",
                boilingPoint: "958 K (685 °C, 1265 °F)",
                density: "4.81 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "VI, IV, II, -II",
                electronegativity: "2.55"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                casNumber: "7782-49-2",
                yearOfDiscovery: "1817"
            }
        }
    }

    getStrontium() {
        return {
            title: "Strontium",
            generalProperties: {
                symbol: "Sr",
                elementCategory: "Alkaline earth metals",
                period: "5",
                protonNumber: "38",
                atomicWeight: "87.62",
                group: "II.A",
                appearance: "Silvery white metallic",
                electronConfiguration: "[Kr] 5s2",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "1050 K (777 °C, 1431 °F)",
                boilingPoint: "1650 K (1377 °C, 2511 °F)",
                density: "2.64 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "I, II",
                electronegativity: "0.95"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                electricalResistivity: "132 nΩ·m",
                casNumber: "7440-24-6",
                yearOfDiscovery: "1793"
            }
        }
    }

    getRhodium() {
        return {
            title: "Rhodium",
            generalProperties: {
                symbol: "Rh",
                elementCategory: "Transition metals",
                period: "5",
                protonNumber: "45",
                atomicWeight: "102.90550",
                group: "VIII.B",
                appearance: "Silvery white metallic",
                electronConfiguration: "[Kr] 4d8 5s1",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "2237 K (1964 °C, 3567 °F)",
                boilingPoint: "3968 K (3695 °C, 6683 °F)",
                density: "12.41 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "-I, I, II, III, IV, V, VI",
                electronegativity: "2.28"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                electricalResistivity: "43.3 nΩ·m",
                casNumber: "7440-16-6:",
                yearOfDiscovery: "1803"
            }
        }
    }

    getNobelium() {
        return {
            title: "Nobelium",
            generalProperties: {
                symbol: "No",
                elementCategory: "Actinium",
                period: "7",
                protonNumber: "102",
                atomicWeight: "259,10",
                group: "III.B",
                appearance: "-",
                electronConfiguration: "[Rn]5f14 7s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "1100 K (827 °C, 1521 °F)",
                boilingPoint: "-",
                density: "9.9 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "II, III",
                electronegativity: "1,3"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                electricalResistivity: "-",
                casNumber: "10028-14-5",
                yearOfDiscovery: "1957"
            }
        }
    }

    getCaesium() {
        return {
            title: "Caesium",
            generalProperties: {
                symbol: "Cs",
                elementCategory: "Alkali metals",
                period: "6",
                protonNumber: "55",
                atomicWeight: "132.905",
                group: "I.A",
                appearance: "Pale gold",
                electronConfiguration: "[Xe] 6s1",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "301.7 K (28.5 °C, 83.3 °F)",
                boilingPoint: "944 K (671 °C, 1240 °F)",
                density: "1.93 kg/dm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "I",
                electronegativity: "0.79"
            },
            miscellanea: {
                crystalStructure: "body-centered cubic",
                electricalResistivity: "205 nΩ·m",
                casNumber: "7440-46-2",
                yearOfDiscovery: "1860"
            }
        }
    }

    getUranium() {
        return {
            title: "Uranium",
            generalProperties: {
                symbol: "U",
                elementCategory: "Actinium",
                period: "7",
                protonNumber: "92",
                atomicWeight: "238,029",
                group: "VI.B",
                appearance: "silvery gray metallic",
                electronConfiguration: "[Rn]5f3 6d1 7s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "1405.3 K (1132.2 °C, 2070 °F)",
                boilingPoint: "4404 K (4131 °C, 7468 °F)",
                density: "19.1 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "III, IV, V, VI",
                electronegativity: "1,38"
            },
            miscellanea: {
                crystalStructure: "orthorhombic",
                electricalResistivity: "0.280 µΩ·m",
                casNumber: "7440-61-1",
                yearOfDiscovery: "1789"
            }
        }
    }

    getZirconium() {
        return {
            title: "Zirconium",
            generalProperties: {
                symbol: "Zr",
                elementCategory: "Transition metals",
                period: "5",
                protonNumber: "40",
                atomicWeight: "91.224",
                group: "IV.B",
                appearance: "Silvery-white",
                electronConfiguration: "[Kr] 4d2 5s2",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "2128 K (1855 °C, 3371 °F)",
                boilingPoint: "4650 K (4377 °C, 7911 °F)",
                density: "6.52 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "II, IV",
                electronegativity: "1.33"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "421 nΩ·m",
                casNumber: "7440-67-7",
                yearOfDiscovery: "1789"
            }
        }
    }

    getFlorine() {
        return {
            title: "Florine",
            generalProperties: {
                symbol: "F",
                elementCategory: "Halogens",
                period: "2",
                protonNumber: "9",
                atomicWeight: "18.998403",
                group: "Group",
                appearance: "gas: very pale youllow",
                electronConfiguration: "liquid: bright yellow",
                radioactive: false
            },
            physicalProperties: {
                phase: "[He] 2s2 2p5",
                meltingPoint: "<h3> &ensp;</h3>",
                boilingPoint: "gas",
                density: "53.48 K (−219.67 °C, −363.41 °F)"
            },
            atomicProperties: {
                oxidationStates: "1.090 kg/m<sup>3</sup>",
                electronegativity: "<h3> &ensp;</h3>"
            },
            miscellanea: {
                crystalStructure: "3.98",
                electricalResistivity: "<h3> &ensp;</h3>",
                casNumber: "cubic",
                yearOfDiscovery: "-"
            }
        }
    }

    getXenon() {
        return {
            title: "Xenon",
            generalProperties: {
                symbol: "Xe",
                elementCategory: "Rare gases",
                period: "5",
                protonNumber: "54",
                atomicWeight: "131.293",
                group: "Group",
                appearance: "Colorless gas",
                electronConfiguration: "[Kr] 4d10 5s2 5p6",
                radioactive: false
            },
            physicalProperties: {
                phase: "Gas",
                meltingPoint: "161.40 K (−111.75 °C, −169.15 °F)",
                boilingPoint: "165.051 K (−108.099 °C, −162.578 °F)",
                density: "5.894 x 10<sup>-3</sup> kg/dm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "0, I, II, IV, VI, VIII",
                electronegativity: "2.6"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                casNumber: "7440-63-3",
                yearOfDiscovery: "1898"
            }
        }
    }

    getBismuth() {
        return {
            title: "Bismuth",
            generalProperties: {
                symbol: "Bi",
                elementCategory: "Other metals",
                period: "6",
                protonNumber: "83",
                atomicWeight: "208,98040",
                group: "V.A",
                appearance: "lustrous brownish silver",
                electronConfiguration: "[Xe]4f14 5d10 6s2 6p3",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "544.7 K (271.5 °C, 520.7 °F)",
                boilingPoint: "1837 K (1564 °C, 2847 °F)",
                density: "9.78 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "-III, III, V",
                electronegativity: "2,02"
            },
            miscellanea: {
                crystalStructure: "​rhombohedral",
                electricalResistivity: "1.29 µΩ·m",
                casNumber: "7440-69-9",
                yearOfDiscovery: "≈ 1400"
            }
        }
    }

    getBarium() {
        return {
            title: "Barium",
            generalProperties: {
                symbol: "Ba",
                elementCategory: "Alkaline earth metals",
                period: "6",
                protonNumber: "56",
                atomicWeight: "137.33",
                group: "II.A",
                appearance: "Silvery gray",
                electronConfiguration: "[Xe] 6s2",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "1000 K (727 °C, 1341 °F)",
                boilingPoint: "2118 K (1845 °C, 3353 °F)",
                density: "3.51 kg/dm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "II",
                electronegativity: "0.89"
            },
            miscellanea: {
                crystalStructure: "body-centered cubic",
                electricalResistivity: "332 nΩ·m",
                casNumber: "7440-39-3",
                yearOfDiscovery: "1808"
            }
        }
    }

    getMoscovium() {
        return {
            title: "Moscovium",
            generalProperties: {
                symbol: "Mc",
                elementCategory: "Other metals",
                period: "7",
                protonNumber: "115",
                atomicWeight: "288,19",
                group: "V.A",
                appearance: "-",
                electronConfiguration: "[Rn] 5f14 6d10 7s2 7p3",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "670 K (400 °C, 750 °F)",
                boilingPoint: "~1400 K (~1100 °C, ~2000 °F)",
                density: "13.5 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "I, III",
                electronegativity: "-"
            },
            miscellanea: {
                crystalStructure: "-",
                electricalResistivity: "-",
                casNumber: "54085-64-2",
                yearOfDiscovery: "2004"
            }
        }
    }

    getCopper() {
        return {
            title: "Copper",
            generalProperties: {
                symbol: "Cu",
                elementCategory: "Transition metals",
                period: "4",
                protonNumber: "29",
                atomicWeight: "63.546",
                group: "I.B",
                appearance: "red-orange metallic luster",
                electronConfiguration: "[Ar] 3d10 4s1",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "1357.77 K (1084.62 °C, 1984.32 °F)",
                boilingPoint: "2835 K (2562 °C, 4643 °F)",
                density: "8.94 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "IV, III, II, I",
                electronegativity: "1.9"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                electricalResistivity: "Electrical resistivity:",
                casNumber: "7440-50-8"
            }
        }
    }

    getGold() {
        return {
            title: "Gold",
            generalProperties: {
                symbol: "Au",
                elementCategory: "Transition metals",
                period: "6",
                protonNumber: "79",
                atomicWeight: "196,97",
                group: "I.B",
                appearance: "metallic yellow",
                electronConfiguration: "[Xe]4f14 5d10 6s1",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "1337.33 K (1064.18 °C, 1947.52 °F)",
                boilingPoint: "3243 K (2970 °C, 5378 °F)",
                density: "19,3 g/cm³"
            },
            atomicProperties: {
                oxidationStates: "-III, -II, -I, I, II, III, V",
                electronegativity: "2,54"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                electricalResistivity: "22.14 nΩ·m",
                casNumber: "7440-57-5",
                yearOfDiscovery: "-"
            }
        }
    }

    getAntimony() {
        return {
            title: "Antimony",
            generalProperties: {
                symbol: "Sb",
                elementCategory: "Metalloid",
                period: "5",
                protonNumber: "51",
                atomicWeight: "121.760",
                group: "V.A",
                appearance: "Silvery lustrous gray",
                electronConfiguration: "[Kr] 4d10 5s2 5p3",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "903.78 K (630.63 °C, 1167.13 °F)",
                boilingPoint: "1908 K (1635 °C, 2975 °F)",
                density: "6.697 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "-III. III, V",
                electronegativity: "2.05"
            },
            miscellanea: {
                crystalStructure: "rhombohedral",
                electricalResistivity: "417 nΩ·m",
                casNumber: "7440-36-0"
            }
        }
    }

    getCurium() {
        return {
            title: "Curium",
            generalProperties: {
                symbol: "Cm",
                elementCategory: "Actinium",
                period: "7",
                protonNumber: "96",
                atomicWeight: "247,070",
                group: "III.B",
                appearance: "silvery metallic",
                electronConfiguration: "[Rn]5f7 6d1 7s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "1613 K (1340 °C, 2444 °F)",
                boilingPoint: "3383 K (3110 °C, 5630 °F)",
                density: "13.51 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "II, III, IV",
                electronegativity: "1,3"
            },
            miscellanea: {
                crystalStructure: "double hexagonal close-packed",
                electricalResistivity: "1.25 µΩ·m",
                casNumber: "7440-51-9",
                yearOfDiscovery: "1944"
            }
        }
    }

    getMendelevium() {
        return {
            title: "Mendelevium",
            generalProperties: {
                symbol: "Md",
                elementCategory: "Actinium",
                period: "7",
                protonNumber: "101",
                atomicWeight: "258,10",
                group: "III.B",
                appearance: "-",
                electronConfiguration: "[Rn]5f13 7s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "1100 K (827 °C, 1521 °F)",
                boilingPoint: "-",
                density: "10.3 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "II, III",
                electronegativity: "1,3"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                electricalResistivity: "-",
                casNumber: "7440-11-1",
                yearOfDiscovery: "1955"
            }
        }
    }

    getCalcium() {
        return {
            title: "Calcium",
            generalProperties: {
                symbol: "Ca",
                elementCategory: "Alkaline earth metals",
                period: "4",
                protonNumber: "20",
                atomicWeight: "40.078",
                group: "II.A",
                appearance: "Silver",
                electronConfiguration: "[Ar] 4s2",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "1115 K (842 °C, 1548 °F)",
                boilingPoint: "1757 K (1484 °C, 2703 °F)",
                density: "1550 kg/m<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "II, I",
                electronegativity: "1.00"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                electricalResistivity: "33,6 nΩ·m",
                casNumber: "7440-70-2",
                yearOfDiscovery: "1808"
            }
        }
    }

    getScandium() {
        return {
            title: "Scandium",
            generalProperties: {
                symbol: "Sc",
                elementCategory: "Transition metals",
                period: "4",
                protonNumber: "21",
                atomicWeight: "44.9559",
                group: "III.B",
                appearance: "Silvery white",
                electronConfiguration: "[Ar] 3d1 4s2",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "1814 K (1541 °C, 2806 °F)",
                boilingPoint: "3109 K (2836 °C, 5136 °F)",
                density: "2,985 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "III",
                electronegativity: "1.36"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "562 nΩ·m",
                casNumber: "7440-20-2",
                yearOfDiscovery: "1879"
            }
        }
    }

    getNiobium() {
        return {
            title: "Niobium",
            generalProperties: {
                symbol: "Nb",
                elementCategory: "Transition metals",
                period: "5",
                protonNumber: "41",
                atomicWeight: "92.9063",
                group: "V.B",
                appearance: "Gray metallic",
                electronConfiguration: "[Kr] 4d4 5s1",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "2750 K (2477 °C, 4491 °F)",
                boilingPoint: "5017 K (4744 °C, 8571 °F)",
                density: "8.57 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "-I, II, III, IV, V",
                electronegativity: "1.6"
            },
            miscellanea: {
                crystalStructure: "body centered cubic",
                electricalResistivity: "152 Ω·m",
                casNumber: "7440-03-1",
                yearOfDiscovery: "1801"
            }
        }
    }

    getYtterbium() {
        return {
            title: "Ytterbium",
            generalProperties: {
                symbol: "Yb",
                elementCategory: "Lanthanoid",
                period: "6",
                protonNumber: "70",
                atomicWeight: "173,045",
                group: "III.B",
                appearance: "silvery-white",
                electronConfiguration: "[Xe]4f14 6s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "1097 K (824 °C, 1515 °F)",
                boilingPoint: "1469 K (1196 °C, 2185 °F)",
                density: "6,90 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "I, II, III",
                electronegativity: "1,1"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                electricalResistivity: "0.250 µΩ·m",
                casNumber: "7440-64-4",
                yearOfDiscovery: "1878"
            }
        }
    }

    getGadolinium() {
        return {
            title: "Gadolinium",
            generalProperties: {
                symbol: "Gd",
                elementCategory: "Lanthanoid",
                period: "6",
                protonNumber: "64",
                atomicWeight: "157.25",
                group: "III.B",
                appearance: "Silvery-white",
                electronConfiguration: "[Xe] 4f7 5d1 6s2",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "1585 K (1312 °C, 2394 °F)",
                boilingPoint: "3273 K (3000 °C, 5432 °F)",
                density: "7.9 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "I, II, III",
                electronegativity: "1.20"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "1.31 µΩ·m",
                casNumber: "7440-54-2",
                yearOfDiscovery: "1880"
            }
        }
    }

    getGermanium() {
        return {
            title: "Germanium",
            generalProperties: {
                symbol: "Ge",
                elementCategory: "Metalloid",
                period: "4",
                protonNumber: "32",
                atomicWeight: "72.64",
                group: "IV.A",
                appearance: "Grayish white",
                electronConfiguration: "[Ar] 3d10 4s2 4p2",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "1211.40 K (938.25 °C, 1720.85 °F)",
                boilingPoint: "3106 K (2833 °C, 5131 °F)",
                density: "5.323 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "IV, II, -IV",
                electronegativity: "2.01"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                electricalResistivity: "1 Ω·m",
                casNumber: "7440-56-4",
                yearOfDiscovery: "1866"
            }
        }
    }

    getEinsteinium() {
        return {
            title: "Einsteinium",
            generalProperties: {
                symbol: "Es",
                elementCategory: "Actinium",
                period: "7",
                protonNumber: "99",
                atomicWeight: "254,088",
                group: "III.B",
                appearance: "silvery",
                electronConfiguration: "[Rn]5f11 7s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "1133 K (860 °C, 1580 °F)",
                boilingPoint: "1269 K (996 °C, 1825 °F)",
                density: "8.84 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "III",
                electronegativity: "1,3"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                electricalResistivity: "-",
                casNumber: "7429-92-7",
                yearOfDiscovery: "1952"
            }
        }
    }

    getNeon() {
        return {
            title: "Neon",
            generalProperties: {
                symbol: "Ne",
                elementCategory: "Rare gases",
                period: "2",
                protonNumber: "10",
                atomicWeight: "20.183",
                group: "VIII.A",
                appearance: "Colorless gas",
                electronConfiguration: "[He] 2s2 2p6",
                radioactive: false
            },
            physicalProperties: {
                phase: "gas",
                meltingPoint: "24.56 K (−248.59 °C, −415.46 °F)",
                boilingPoint: "27.104 K (−246.046 °C, −410.883 °F)",
                density: "0,901 kg/m<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "0",
                electronegativity: "4,0"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                electricalResistivity: "-",
                casNumber: "7440-01-9",
                yearOfDiscovery: "1897"
            }
        }
    }

    getAstatine() {
        return {
            title: "Astatine",
            generalProperties: {
                symbol: "At",
                elementCategory: "Halogens",
                period: "6",
                protonNumber: "85",
                atomicWeight: "210",
                group: "VII.A",
                appearance: "unknown",
                electronConfiguration: "[Xe]4f14 5d10 6s2 6p5",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "575 K (302 °C, 576 °F)",
                boilingPoint: "610 K (337 °C, 639 °F)",
                density: "6.35±0.15 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "−I, I, III, V, VII",
                electronegativity: "2,2"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                electricalResistivity: "-",
                casNumber: "7440-68-8",
                yearOfDiscovery: "1940"
            }
        }
    }

    getSulfur() {
        return {
            title: "Sulfur",
            generalProperties: {
                symbol: "S",
                elementCategory: "Non metals",
                period: "3",
                protonNumber: "16r",
                atomicWeight: "32.065",
                group: "VI.A",
                appearance: "lemon yellow sintered microcrystals",
                electronConfiguration: "[Ne] 3s2 3p4",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "388.36 K (115.21 °C, 239.38 °F)",
                boilingPoint: "717.8 K (444.6 °C, 832.3 °F)",
                density: "2,070 g/cm<sup>3</sup> (alpha), 1,960 g/cm<sup>3</sup> (beta), 1,920 g/cm<sup>3</sup> (gamma)"
            },
            atomicProperties: {
                oxidationStates: "VI, IV, III, II, I, -I, -II",
                electronegativity: "2.4"
            },
            miscellanea: {
                crystalStructure: "orthorhomic",
                electricalResistivity: "2×10<sup>15</sup> Ω·m",
                casNumber: "7704-34-9"
            }
        }
    }

    getRadon() {
        return {
            title: "Radon",
            generalProperties: {
                symbol: "Rn",
                elementCategory: "Rare gases",
                period: "6",
                protonNumber: "86",
                atomicWeight: "222",
                group: "VIII.A",
                appearance: "Colorless gas",
                electronConfiguration: "[Xe]4f14 5d10 6s2 6p6",
                radioactive: true
            },
            physicalProperties: {
                phase: "gas",
                meltingPoint: "202 K (−71 °C, −96 °F)",
                boilingPoint: "211.5 K (−61.7 °C, −79.1 °F)",
                density: "9.73 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "0, II, VI",
                electronegativity: "2,2"
            },
            miscellanea: {
                crystalStructure: "face-centered cubic",
                electricalResistivity: "-",
                casNumber: "10043-92-2",
                yearOfDiscovery: "1898"
            }
        }
    }

    getHelium() {
        return {
            title: "Helium",
            generalProperties: {
                symbol: "He",
                elementCategory: "Rare gases",
                period: "1",
                protonNumber: "2",
                atomicWeight: "4.002602",
                group: "VIII.A",
                appearance: "Colorless gas",
                electronConfiguration: "1s2",
                radioactive: false
            },
            physicalProperties: {
                phase: "gas",
                meltingPoint: "0.95 K (−272.20 °C, −457.96 °F)",
                boilingPoint: "4.222 K (−268.928 °C, −452.070 °F)",
                density: "0,179 kg/m<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "0",
                electronegativity: "-"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "10<sup>-8</sup>Ωm",
                casNumber: "7440-59-7",
                yearOfDiscovery: "1868"
            }
        }
    }

    getCalifornium() {
        return {
            title: "Californium",
            generalProperties: {
                symbol: "Cf",
                elementCategory: "Actinium",
                period: "7",
                protonNumber: "98",
                atomicWeight: "251,080",
                group: "III.B",
                appearance: "silvery",
                electronConfiguration: "[Rn]5f10 7s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "1173 K (900 °C, 1652 °F)",
                boilingPoint: "1743 K (1470 °C, 2678 °F)",
                density: "15.1 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "II, III, IV",
                electronegativity: "1,3"
            },
            miscellanea: {
                crystalStructure: "double hexagonal close-packed",
                electricalResistivity: "-",
                casNumber: "7440-71-3",
                yearOfDiscovery: "1950"
            }
        }
    }

    getMagnesium() {
        return {
            title: "Magnesium",
            generalProperties: {
                symbol: "Mg",
                elementCategory: "Alkaline earth metals",
                period: "3",
                protonNumber: "12",
                atomicWeight: "24.3050",
                group: "II.A",
                appearance: "Shiny grey solid",
                electronConfiguration: "[Ne] 3s2",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "923 K (650 °C, 1202 °F)",
                boilingPoint: "1363 K (1091 °C, 1994 °F)",
                density: "1 738 kg/m<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "II, I",
                electronegativity: "1.2"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "43,9 nΩ·m (20 °C)",
                casNumber: "7439-95-4",
                yearOfDiscovery: "1808"
            }
        }
    }

    getCerium() {
        return {
            title: "Cerium",
            generalProperties: {
                symbol: "Ce",
                elementCategory: "Lanthanoid",
                period: "6",
                protonNumber: "58",
                atomicWeight: "140.116",
                group: "Group",
                appearance: "III.B",
                electronConfiguration: "[Xe] 4f1 5d1 6s2",
                radioactive: false
            },
            physicalProperties: {
                phase: "Solid",
                meltingPoint: "1068 K (795 °C, 1463 °F)",
                boilingPoint: "3716 K (3443 °C, 6229 °F)",
                density: "6,770 kg·dm<sup>−3</sup>"
            },
            atomicProperties: {
                oxidationStates: "I, II, III, IV",
                electronegativity: "1.12"
            },
            miscellanea: {
                crystalStructure: "double hexagonal close-package, face-centered cubic",
                electricalResistivity: "828 nΩ·m",
                casNumber: "7440-45",
                yearOfDiscovery: "1803"
            }
        }
    }

    getHafnium() {
        return {
            title: "Hafnium",
            generalProperties: {
                symbol: "Hf",
                elementCategory: "Transition metals",
                period: "6",
                protonNumber: "72",
                atomicWeight: "178,49",
                group: "IV.B",
                appearance: "steel gray",
                electronConfiguration: "[Xe]4f14 5d2 6s2",
                radioactive: true
            },
            physicalProperties: {
                phase: "solid",
                meltingPoint: "2506 K (2233 °C, 4051 °F)",
                boilingPoint: "4876 K (4603 °C, 8317 °F)",
                density: "13,31 g/cm<sup>3</sup>"
            },
            atomicProperties: {
                oxidationStates: "-II, I, II, III, IV",
                electronegativity: "1,3"
            },
            miscellanea: {
                crystalStructure: "hexagonal",
                electricalResistivity: "331 nΩ·m",
                casNumber: "7440-58-6",
                yearOfDiscovery: "1923"
            }
        }
    }
}

export default new ElementsHelper();
