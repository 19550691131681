import React, { Component } from "react";
import { Link, Route, Routes } from "react-router-dom";
import ResizeObserver from "rc-resize-observer";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import PeriodicTable from "./components/periodic-table.component";
import Element from "./components/element.component";

import imgIconHeader from "./icons/icon.svg";
import imgAndroid from "./icons/android-dark.svg";
import imgAbout from "./icons/about.svg";
import Footer from "./components/footer.component";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footerClassName: "footer footer_fixed",
      oldHeightOfGrid: 0,
    };

    this.updateFooterPosition = this.updateFooterPosition.bind(this);
  }

  componentDidMount() {
    this.updateFooterPosition();
    window.addEventListener("resize", this.updateFooterPosition);
  }

  updateFooterPosition() {
    if (
      this.state.oldHeightOfGrid !==
      document.getElementById("page_content_wrapper").clientHeight
    ) {
      const heightOfGrid = document.getElementById(
        "page_content_wrapper"
      ).clientHeight;
      const heightOfWindow = window.innerHeight;
      var newFooterClassName = "footer footer_";
      if (heightOfWindow < heightOfGrid + 160) {
        newFooterClassName += "bottom";
      } else {
        newFooterClassName += "fixed";
      }

      this.setState({
        oldHeightOfGrid: heightOfGrid,
        footerClassName: newFooterClassName,
      });
    }
  }

  componentDidUpdate() {
    this.updateFooterPosition();
  }

  render() {
    return (
      <div>
        <header>
          <div>
            <Link to="/" className="title">
              <img src={imgIconHeader} alt="Chemistry" height="40" width="40" />
              <h1>Chemistry</h1>
            </Link>

            <nav>
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.davidsimak.dev/projects/chemistry"
                  >
                    <img
                      title="About app"
                      src={imgAbout}
                      alt="About"
                      height="40"
                      width="40"
                    />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://play.google.com/store/apps/details?id=cz.david_simak.chemistry"
                  >
                    <img
                      title="Android App"
                      src={imgAndroid}
                      alt="Android"
                      height="40"
                      width="40"
                    />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>

        <ResizeObserver onResize={this.updateFooterPosition}>
          <div className="container mt-3" id="page_content_wrapper">
            <Routes>
              <Route exact path="/" element={<PeriodicTable />} />
              <Route exact path="/periodic-table" element={<PeriodicTable />} />
              <Route exact path="/elements" element={<PeriodicTable />} />
              <Route exact path="/elements/:element" element={<Element />} />
            </Routes>
          </div>
        </ResizeObserver>

        <Footer customClass={this.state.footerClassName} />
      </div>
    );
  }
}

export default App;
